import { useEffect, useState } from "react";
import {
  Row,
  Typography,
  Col,
  Pagination,
  Spin,
  Empty,
  message,
  Checkbox,
} from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import { getOrdersUnBilled } from "./network_Request";
import {
  double_to_string,
  doubleDT_to_string,
} from "../../../../../../constants";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function WorkOrdersUnbilled(props) {
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { updateData, setUpdateData, filter, dates, stkeys, setStkeys } = props;

  const [count, setCount] = useState(0);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const checks = await getOrdersUnBilled((page - 1) * 100, filter, dates);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks?.list ? checks?.list : []);
    setCount(checks?.count ? checks?.count : 0);
    setLoad(false);
  }, [page, updateData, filter, dates]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              borderTop: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0px",
            }}
          >
            <Col
              span={10}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.titel" />
              </Text>
            </Col>
            <Col
              span={5}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.created" />
              </Text>
            </Col>
            <Col
              span={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="Arbeitszeit (verrechnet)" />
              </Text>
            </Col>
            <Col
              span={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.ressources" />
              </Text>
            </Col>
            <Col xs={1}></Col>
          </Row>

          {data?.length === 0 ? (
            <Empty description="Keine Aufträge vorhanden!" />
          ) : null}

          {data?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0px",
                }}
              >
                <Col
                  span={10}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(
                      `/main/baseData-Order?editOrderKey=${item.stkey}`
                    );
                  }}
                >
                  <p style={{ margin: 0, color: "#038fde" }}>
                    {item?.cfeld1 ? item?.cfeld1 : "-"}
                  </p>
                </Col>
                <Col
                  span={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld3 ? doubleDT_to_string(item?.nfeld3) : null}
                  </Text>
                  <br />
                  <Text>{item?.cfeld4}</Text>
                </Col>
                <Col
                  span={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    <p>{(item?.nfeld5 ? doubleDT_to_string(item?.nfeld5) : "-") + " - "}</p> { (item?.nfeld6 ? doubleDT_to_string(item?.nfeld6) : "-")}
                  </Text>
                  
                </Col>
                <Col
                  span={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{item?.cfeld5 ? item?.cfeld5 : "-"}</Text>
                </Col>
                <Col xs={1}>
                  <Checkbox
                    checked={stkeys.includes(item.stkey)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStkeys([...stkeys, item.stkey]);
                      } else {
                        setStkeys(stkeys.filter((key) => key !== item.stkey));
                      }
                    }}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              padding: "10px 30px",
              margin: "0 15px",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={100}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
