import { url } from "../../../constants";
import axios from "axios";

export const getTasks = async (start, type, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g20",
        stClass: "1",
        type: "none",
        limitFrom: start,
        limitTo: start + 10,
        orderBy: "nfeld3 DESC,nfeld5 DESC,nfeld6 DESC,nfeld7 DESC",
        cfields: 
        type === "unverified" ? [{ fieldNo: "cfeld29", val: "unverifiziert", opVal: "=" }] :
        [
          {
            fieldNo: "cfeld1",
            val: search, 
            opVal: "=",
          },
          {
            fieldNo: "cfeld29",
            val: "verifiziert", 
            opVal: "=",
            exact: "true"
          },
        ],
        nfields:
          type === "all"
            ? [{ fieldNo: "nfeld3", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "planned"
            ? [{ fieldNo: "nfeld6", fromVal: "0", toVal: "0", opVal: ">" },{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: "=" },{ fieldNo: "nfeld8", fromVal: "0", toVal: "0", opVal: "=" }]
            : type === "inProgress"
            ? [{ fieldNo: "nfeld6", fromVal: "0", toVal: "0", opVal: ">" },{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: "=" },{ fieldNo: "nfeld8", fromVal: "0", toVal: "0", opVal: "=" }]
            : type === "finished"
            ? [{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: ">" },{ fieldNo: "nfeld8", fromVal: "0", toVal: "0", opVal: "=" }]
            : type === "refused"
            ? [{ fieldNo: "nfeld8", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "open"
            ? [{ fieldNo: "nfeld3", fromVal: "0", toVal: "0", opVal: ">" },{ fieldNo: "nfeld5", fromVal: "0", toVal: "0", opVal: "=" },{ fieldNo: "nfeld6", fromVal: "0", toVal: "0", opVal: "=" },{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: "=" },{ fieldNo: "nfeld8", fromVal: "0", toVal: "0", opVal: "=" }]
            : [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 20,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
        cfeld29: "verifiziert",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 20,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        active: 0,
        group: 20,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createRequestsFromEmail = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}createRequestsFromEmail`,
      {
        group: "20",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getRequestStatus = async (dates) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getRequestStatusString`,
      {
        
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTaskToOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}addRequestToOrderList`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};