import React, { useState, useEffect } from "react";
import { Form, Input, Select, message } from "antd";
import {
  addOrder_baseDataBill,
  editOrder_baseData,
  getCostCenter,
} from "./network_Request";
import { useHistory } from "react-router-dom";

const BaseDataForm = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { refVal, stkeys, data } = props;

  const [costCenter, setCostCenter] = useState([]);

  useEffect(() => {
    const fetchCostCenter = async () => {
      try {
        const data = await getCostCenter();
        setCostCenter([...data]);
      } catch (error) {
        console.error("Failed to fetch cost center data:", error);
      }
    };

    fetchCostCenter();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (data?.stkey) {
        const response = await editOrder_baseData({
          ...data,
          cfeld4: values?.title,
          cfeld6: values?.comment,
          cfeld8: values?.sumRental,
        });
        if (response?.success === "saved") {
          message.success("Successfully updated!");
          history.push(`/main/workOrderbills`);
        } else {
          message.error("Update failed!");
        }
      } else {
        const response = await addOrder_baseDataBill({
          ...values,
          stkeys: stkeys.map((stkey) => ({ stkey })),
        });
        if (response?.filename) {
          message.success("Successfully inserted!");
          history.push(`/main/workOrderbills`);
        } else {
          message.error("Insertion failed!");
        }
      }
    } catch (error) {
      console.error("Failed to insert/update data:", error);
      message.error("An error occurred while saving data.");
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "100%",
        backgroundColor: "#FFF",
        borderRadius: "12px",
        border: "1px solid #eaeaea",
      }}
    >
      <h2>{data?.stkey ? "Rechnungsdaten bearbeiten" : "Eine neue Rechnung erstellen"}</h2>
      <Form
        form={form}
        ref={refVal}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          title: data?.cfeld4 ?? "",
          comment: data?.cfeld6 ?? "",
          sumRental: data?.cfeld8 ?? "",
        }}
      >
        <Form.Item
          label="Titel der Rechnung"
          name="title"
          rules={[{ required: true, message: "Pflichtfeld!" }]}
        >
          <Input placeholder="Titel der Rechnung" />
        </Form.Item>
        {/*
        <Form.Item label="Dienststelle für die Rechnungsnummer" name="costcenter">
          <Select
            options={[
              ...costCenter?.map((item) => {
                return {
                  label: item?.cfeld1,
                  value: item?.cfeld1,
                };
              }),
            ]}
          />
        </Form.Item>
        */}
        <Form.Item
          label="Leihgegenstände Summe"
          name="sumRental"
          rules={[{ required: false }]}
        >
          <Input placeholder="Summe Leihgegenstände"/>
        </Form.Item>
        <Form.Item
          label="Kommentar"
          name="comment"
          rules={[{ required: false }]}
        >
          <Input.TextArea placeholder="Eine Bemerkung zur Rechnung ablegen" rows={4} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default BaseDataForm;
