import Typography from "antd/lib/typography/Typography";
import { Button, Card } from "antd";
import { Input, Form, Row, Col, DatePicker, message } from "antd";
import { Transfer } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { AssignStaffForOrder,DeAssignStaffForOrder, addOrder_baseData, getAllStaff, getStaffForOrder } from "./network_Request";
import {
  string_to_doubleDT,
  string_to_double,
} from "../../../../../../constants";
import IntlMessages from "../../../../../../util/IntlMessages";
import { useHistory } from "react-router-dom";
import { Checkbox } from "antd";
import Search from "antd/lib/input/Search";
import { LeftOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const success = () => {
  message.success("MitarbeiterInnen wurden zum Auftrag gespeichert!");
};

const error = () => {
  message.error("Ein Fehler ist passiert - bitte nochmal probieren!");
};

export default function StaffForOrder(props) {
  const [data, setData] = useState([]);

  const [count, setCount] = useState(0);
  const history = useHistory();

  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const stKeyFromURL = queryParams.get('stKey');
    console.log(stKeyFromURL)

  
   

  useEffect(async () => {
    if(stKeyFromURL || props.orderStaff)
{   
   const stkey = stKeyFromURL ? stKeyFromURL : props.orderStaff.stkey;
   const checks = await getStaffForOrder(stkey);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    const allStaff = checks?.list || [];

    setData(allStaff);
    
    const staffWithNField10Equals1 = allStaff.filter(item => item.nfeld10 === 1.0);
    
    setTargetKeys(staffWithNField10Equals1);
    setCount(checks?.count ? checks?.count : 0);}
 
    else{
      setData([])
    }
  

    
   
  }, [stKeyFromURL,props.orderStaff]);

 

  const handleSave = async () => {
    try {
      //const response = await AssignStaffForOrder(targetKeys);
      const dataNotInTarget = data.filter(item => !targetKeys.some(targetItem => targetItem.detkey === item.detkey));
      const response = await AssignStaffForOrder(targetKeys,dataNotInTarget);
    // Pass the filtered data to the DeAssignStaffForOrder function
    //const response1 = await DeAssignStaffForOrder(dataNotInTarget);
      success(); 
      
    } catch (error) {
      error(); 
      console.error(error);
    }
  };
  const [targetKeys, setTargetKeys] = useState([]);

  const handleChange = (newTargetKeys, direction, moveKeys) => {
    console.log(newTargetKeys, direction, moveKeys);
    const selectedStaff = data.filter(item => newTargetKeys.includes(item.detkey))
    setTargetKeys(selectedStaff);
  };

  const filterOption = (inputValue, option) => {
    // Ensure option exists and has cfeld1 before using indexOf
    if (!option || !option.cfeld1) {
      return false;
    }
    
    return option.cfeld1.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };

  return (
    <>
      <Card
        style={{
          borderRadius: "8px",
          margin: "20px 70px 20px 60px",
          padding: 0,
          height: "auto",
          // width: "100%",
        }}
      >
        <Typography.Text><IntlMessages id="workorder.staffforthisorder" /></Typography.Text>

        <Row style={{ width: "100%", marginTop: "30px" }}>
          <Col xs={18}>
            <Transfer
              selectAllLabels={[
                ({ selectedCount, totalCount }) => (
                  <span>
                    {totalCount}
                    {<IntlMessages id="workorder.staff" />}
                  </span>
                ),
                ({ selectedCount, totalCount }) => (
                  <span>
                    {totalCount}
                    {<IntlMessages id="workorder.staffmembersadded" />}
                  </span>
                ),
              ]}
              dataSource={data}
              showSearch
              listStyle={{
                width: 300,
                height: 300,
              }}
              targetKeys={targetKeys.map(item => item.detkey)}
              onChange={handleChange}
              render={(item) => (<span style={{ color: item.nfeld9 === 1.0 ? "orange" : item.nfeld8 === 1.0 ? "red" : "inherit" }}>
                {item.cfeld1 + " (" + item.cfeld3 + ") "}
              </span>)}
              rowKey={(item) => item.detkey}
              filterOption={filterOption}
            />
          </Col>

          <Col
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button type="primary"  onClick={handleSave} style={{ padding:"0px 60px"}}>
              <IntlMessages id="save"/>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}
