import { useEffect, useState } from "react";
import { Row, Typography, Col, Pagination, Spin, Empty } from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import { getOrderBills } from "./network_Request";
import { doubleDT_to_string } from "../../../../../../constants";
import { useHistory } from "react-router";
import red from "../assets/red.png";
import green from "../assets/green.png";
import MoreAction from "./MoreActions";
const { Text } = Typography;

export default function WorkOrdersBillsMain(props) {
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { updateData, setUpdateData, filter, dates } = props;

  const [count, setCount] = useState(0);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const checks = await getOrderBills((page - 1) * 10, filter, dates);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks?.list ? checks?.list : []);
    setCount(checks?.count ? checks?.count : 0);
    setLoad(false);
  }, [page, updateData, filter, dates]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0 15px 5px 15px",
            }}
          >
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.status" />
              </Text>
            </Col>
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="No." />
              </Text>
            </Col>
            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="Nummernkreis / Dienststelle" />
              </Text>
            </Col>
            <Col
              span={8}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.titel" />
              </Text>
            </Col>
           
            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.created" />
              </Text>
            </Col>

            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="weitergeleitet" />
              </Text>
            </Col>

            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text></Text>
            </Col>
          </Row>

          {data?.length === 0 ? (
            <Empty description="Erstelle die erste Rechnung!" />
          ) : null}

          {data?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0 15px",
                }}
              >
                <Col
                  span={2}
                  style={{
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  {item.nfeld2 === 0 ? (
                    <img src={red} alt="red" width="30px" />
                  ) : (
                    <img src={green} alt="green" width="30px" />
                  )}
                </Col>
                <Col
                  span={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <Typography.Text style={{ fontWeight: 600 }}>
                  <Text>
                    {item?.cfeld1} {item?.nfeld3} 
                  </Text>
                  </Typography.Text>
                </Col>
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Typography.Text style={{ fontWeight: 600 }}>
                  <Text >{item?.cfeld7}</Text>
                  </Typography.Text>
                  <br />
                  <Text>
                    {item?.cfeld5 ? item?.cfeld5 : "-"}
                  </Text>
                  
                </Col>
                <Col
                  span={8}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // const encodedItem = encodeURIComponent(JSON.stringify(item));
                    history.push(
                      `/main/workOrders-baseDataBill?editOrderKey=${item.stkey}`
                    );
                  }}
                >
                  <p style={{ margin: 0, color: "#038fde" }}>
                    {item?.cfeld4 ? item?.cfeld4 : "-"}
                  </p>
                </Col>
                
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld1 ? doubleDT_to_string(item?.nfeld1) : "-"}
                  </Text>
                  <br />
                  <Text>{item?.cfeld3}</Text>
                </Col>
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld2 ? doubleDT_to_string(item?.nfeld2) : "-"}
                  </Text>
                </Col>
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <MoreAction
                    item={item}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              padding: "10px 30px",
              margin: "0 15px",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
