import moment from "moment";
import CheckroundForm from "./components/CheckroundForm";
import { useEffect, useState } from "react";
import {
  getObjectsForCheckRoundTemplate,
  updateCheckRoundObjects,
} from "./network_requests";
import {
  Spin,
  Row,
  Col,
  Empty,
  Typography,
  Checkbox,
  Button,
  message,
} from "antd";

export default function EditCheckroundTemplate({ state, data }) {
  let daysArray = [];
  if (data?.mon === "1") {
    daysArray.push("mon");
  }
  if (data?.tue === "1") {
    daysArray.push("tue");
  }
  if (data?.wed === "1") {
    daysArray.push("wed");
  }
  if (data?.thu === "1") {
    daysArray.push("thu");
  }
  if (data?.fri === "1") {
    daysArray.push("fri");
  }
  if (data?.sat === "1") {
    daysArray.push("sat");
  }
  if (data?.sun === "1") {
    daysArray.push("sun");
  }
  const [days] = useState([...daysArray]);
  const [load, setLoad] = useState(false);
  const [objects, setObjects] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getObjectsForCheckRoundTemplate({
        stkey: data?.stkey,
        cfeld3: data?.category,
      });
      if (Array.isArray(response)) {
        setObjects(response);
        // Filter objects where roundStKey equals data?.stkey
        const filteredObjects = response.filter(
          (obj) => obj.roundStKey === data?.stkey
        );
        // Combine the filtered objects with data.connectObjects (if any)
        setSelectedObjects([
          ...(data?.connectObjects ?? []),
          ...filteredObjects,
        ]);
      } else {
        // Fallback: just set connectObjects if response is not an array
        setSelectedObjects(data?.connectObjects ?? []);
      }
    };

    fetchData();
  }, [data]);

  return (
    <>
      <CheckroundForm
        state={state}
        data={{
          ...data,
          startTime: moment(data?.startTime, "HH:mm"),
          endTime: moment(data?.endTime, "HH:mm"),
          days: [...days],
        }}
      />

      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={24} style={{ margin: "40px 0 18px 0" }}>
          <Typography.Text style={{ fontSize: "18px" }}>
            Prüfpunkte wählen
          </Typography.Text>
        </Col>
      </Row>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "20px 0 0 0",
        }}
      >
        <Col
          xs={8}
          style={{
            padding: 0,
          }}
        >
          Title
        </Col>
        <Col
          xs={8}
          style={{
            padding: 0,
          }}
        >
          
        </Col>

        <Col
          xs={8}
          style={{
            padding: 0,
          }}
        ></Col>
      </Row>

      {objects?.length === 0 ? (
        <Empty description="Keine Objekte vorhanden!" />
      ) : null}

      {objects?.map((object) => {
        return (
          <Row
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eaeaea",
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={8}
              style={{
                padding: 0,
                cursor: "pointer",
              }}
            >
              <Typography.Text
                style={{
                  color: "#038fde",
                }}
              >
                {object?.objcfeld1}
              </Typography.Text>
            </Col>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
               {object?.objcfeld3 + " " + object?.objcfeld4}
            </Col>
            <Col
              xs={8}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Checkbox
                checked={selectedObjects.includes(object)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedObjects([...selectedObjects, object]);
                  } else {
                    setSelectedObjects(
                      selectedObjects.filter((o) => o !== object)
                    );
                  }
                }}
              />
            </Col>
          </Row>
        );
      })}
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "18px",
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            const response = await updateCheckRoundObjects({
              stkey: data?.stkey,
              category: data?.category,
              connectObjects: [...selectedObjects],
            });
            if (response?.success === "saved") {
              message.success("Checkroud objects are updated");
            }
          }}
        >
          Prüfpunkte zur Runde speichern
        </Button>
      </Row>
    </>
  );
}
