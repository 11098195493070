import React, { useState } from "react";
import {
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Button,
  Typography,
  message,
} from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import { DownOutlined } from "@ant-design/icons";
import UploadDocs from "./Upload";
import {
  cancelBill,
  editOrder_baseData,
  recreateBill,
} from "./network_Request";
import {
  string_to_double,
  string_to_doubleDT,
} from "../../../../../../constants";
import moment from "moment";

const MoreAction = ({ item, updateData, setUpdateData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAction = async (action) => {
    console.log("Action triggered:", action);
    if (action === "cancelBill") {
      const response = await cancelBill(item);
      if (response.status === 200) {
        message.success("Die Rechnung wurde storniert!");
      }
    } else if (action === "signAsPaid") {
      const date = moment(moment()).format("DD.MM.YYYY HH:mm");
      const response = await editOrder_baseData({
        ...item,
        nfeld2: string_to_doubleDT(date),
      });
      if (response.status === 200) {
        message.success("Die Rechnung wurde bezahlt / übermittelt!");
      }
    } else if (action === "recreateBill") {
      const date = moment(moment()).format("DD.MM.YYYY");
      const response = await recreateBill(item?.stkey);
      if (response.status === 200) {
        message.success("Die Rechnung wurde neu erzeugt!");
      }
    }
    setUpdateData(!updateData);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Popconfirm
          title="Möchten Sie die Rechnung stornieren?"
          onConfirm={() => {handleAction("cancelBill");}}
          okText="Yes"
          cancelText="No"
        >
          <div><IntlMessages id="workorder.bill.cancelbill"/></div>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Soll die Rechnung als weitergeleitet markiert werden?"
          onConfirm={() => handleAction("signAsPaid")}
          okText="Yes"
          cancelText="No"
        >
          <div><IntlMessages id="am BH weitergeleitet"/></div>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Soll das Rechnungsdokument neu generiert werden?"
          onConfirm={() => handleAction("recreateBill")}
          okText="Yes"
          cancelText="No"
        >
          <div><IntlMessages id="workorder.bill.recreatebill"/></div>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <div onClick={showModal}><IntlMessages id="workorder.bill.files"/></div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ padding: 20 }}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Typography.Text
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
        >
          <IntlMessages id="more_actions" />
          <DownOutlined
            size="small"
            style={{
              color: "#038fde",
              paddingLeft: "10px",
              fontSize: "10px",
            }}
          />
        </Typography.Text>
      </Dropdown>
      <Modal
        title="Files"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <UploadDocs bill={item} />
      </Modal>
    </div>
  );
};

export default MoreAction;
