import { Button, Col, Row, Select, Typography, DatePicker } from "antd";
import IntlMessages from "../../../../../util/IntlMessages";
import moment from "moment";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { getCostCenter, getFields } from "./components/network_Request";
import WorkOrdersBillsMain from "./components/workOrderBillsMain";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function WorkOrderBills() {
  const navigate = useHistory();
  const [filter, setFilter] = useState("alle");
  const [dates, setDates] = useState([
    moment()?.startOf("year").format("DDMMYYYY"),
    moment()?.endOf("year").format("DDMMYYYY"),
  ]);

  const [updateData, setUpdateData] = useState(false);

  const [costCenter, setCostCenter] = useState([]);
  const [nummernkreise, setNummernkreise] = useState([]);

  useEffect(() => {
    const fetchCostCenter = async () => {
      try {
        const data = await getCostCenter();
        setCostCenter([
          {
            cfeld1: "alle",
          },
          ...data,
        ]);
      } catch (error) {
        console.error("Failed to fetch cost center data:", error);
      }
    };

    //fetchCostCenter();


    const fetchNummernkreise = async () => {
      try {
        const data = await getFields("billnorange");
        setNummernkreise([
          {
            lovvalue: "alle",
          },
          ...data,
        ]);
      } catch (error) {
        console.error("Failed to fetch NUmmernkreisedata:", error);
      }
    };

    fetchNummernkreise();

  }, []);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={4} style={{}}>
          <Text style={{ fontSize: "18px" }}> <IntlMessages id="workorder.bill.workorderbills"/></Text>
        </Col>

        <Col
          xs={6}
          style={{
            color: "#2596be",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <RangePicker
            format={"DD.MM.YYYY"}
            value={[moment(dates[0], "DDMMYYYY"), moment(dates[1], "DDMMYYYY")]}
            onChange={(values, formatString) => {
              const modifiedArray = formatString.map((date) => {
                return date.replace(/\./g, "");
              });
              setDates(modifiedArray);
            }}
          />
        </Col>

        {/*<Col xs={9}>
          <Select
            defaultValue={filter}
            style={{ width: 380 }}
            onChange={(value) => {
              setFilter(value);
            }}
            options={[
              ...costCenter?.map((item) => {
                return {
                  label: item?.cfeld1,
                  value: item?.cfeld1,
                };
              }),
            ]}
          />
        </Col>*/}

        <Col xs={9}>
          <Select
            defaultValue={filter}
            style={{ width: 380 }}
            onChange={(value) => {
              setFilter(value);
            }}
            options={[
              ...nummernkreise?.map((item) => {
                return {
                  label: item?.lovvalue,
                  value: item?.lovvalue,
                };
              }),
            ]}
          />
        </Col>


        <Col xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="middle"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {
              navigate.push("/main/workOrders-baseDataBill");
            }}
          >
            <IntlMessages id="workorder.bill.newbill"/>
          </Button>
        </Col>
      </Row>
      <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />

      <WorkOrdersBillsMain
        filter={filter}
        dates={dates}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
    </>
  );
}
