import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getVehicle,deleteAll } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddVehicle from "./addVehicle";
import EditVehicle from "./editVehicle";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";
import { double_to_string } from "../../../../../../constants";


const { Text } = Typography;

export default function Vehicle() {
  const [vehicle, setVehicle] = useState([]);
  const [addVehicle, setAddVehicle] = useState(false);
  const [editVehicle, setEditVehicle] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const vehicle_ = await getVehicle((page - 1) * 10);
    if (vehicle_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setVehicle(vehicle_?.list ? vehicle_?.list : []);
    setCount(vehicle_?.count ? vehicle_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  console.log(vehicle);

  return (
    <>
      <AddVehicle
        open={addVehicle}
        setOpen={setAddVehicle}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />
      <EditVehicle
        vehicleItem={editVehicle}
        setEstate={setEditVehicle}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Name
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Kennzeichen
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Zulassung
            </Col>

            <Col
              xs={11}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Text>
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {vehicle?.length === 0 ? (
            <Empty description="Keine Fahzeuge vorhanden!" />
          ) : null}

          {vehicle?.map((vehicleItem) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditVehicle(vehicleItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {vehicleItem?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditVehicle(vehicleItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {vehicleItem?.cfeld2}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditVehicle(vehicleItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {double_to_string(vehicleItem?.nfeld1)}
                  </Text>
                </Col>

                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MoreActions state={vehicleItem}
                  onDelete={deleteAll}  update={updateData}
                  setUpdate={setUpdateData}  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddVehicle(!addVehicle);
              }}
            >
              <IntlMessages id="workorder.vehicleitem.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
