import { Row, Typography } from "antd";
import { useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getOrderBills } from "./network_Request";
import WorkOrderUnbilledMain from "./workOrdersUnbiledMain";
import BaseDataForm from "./baseDataForm";
import WorkOrderBilledMain from "./workOrdersBilledMain";

export default function BaseDataOrderBill() {
  const ref = useRef();
  const [editOrderData, setEditOrderData] = useState(null);
  const [load, setLoad] = useState(false);
  const [stkeys, setStkeys] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const editOrderKey = queryParams.get("editOrderKey");

  const fetchAllOrders = async () => {
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      try {
        const response = await getOrderBills((currentPage - 1) * 10);
        const data = response?.list || [];

        const foundOrder = data.find((order) => order.stkey === editOrderKey);

        if (foundOrder) {
          console.log("Found Order:", foundOrder);
          return foundOrder;
        }

        totalPages = Math.ceil(response?.count / 10);
        currentPage++;
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }

    console.log("Order not found");
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      const data_ = await fetchAllOrders();
      setEditOrderData(data_);
      setLoad(false);
    };
    if (editOrderKey) {
      fetchData();
    }
  }, [editOrderKey]);

  return (
    <Row style={{ width: "100%" }}>
      {!load ? (
        <div
          style={{
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <BaseDataForm refVal={ref} stkeys={stkeys} data={editOrderData} />
        </div>
      ) : null}

      <div
        style={{
          // backgroundColor: "#fafafa",
          minHeight: "70vh",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        {editOrderData?.stkey ? (
          <WorkOrderBilledMain stkey={editOrderData?.stkey} refVal={ref} />
        ) : (
          <WorkOrderUnbilledMain
            refVal={ref}
            stkeys={stkeys}
            setStkeys={setStkeys}
          />
        )}
      </div>
    </Row>
  );
}
