import { url, string_to_double } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getOrderBills = async (limitFrom, costCenter, dates) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "3",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: " nfeld3, CAST(cfeld1 AS UNSIGNED)  DESC",
        cfields:
          costCenter === "alle" || !costCenter
            ? []
            : [{ fieldNo: "cfeld7", val: costCenter, opVal: "=" }],
        nfields: dates
          ? [
              {
                fieldNo: "nfeld1",
                fromVal: `${reverseDateFormat(dates[0])}0000`,
                opVal: "between",
                toVal: `${reverseDateFormat(dates[1])}2400`,
              },
            ]
          : [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOrdersBilled = async (stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "1",
        type: "none",
        limitFrom: "0",
        limitTo: "100",
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "gisid", val: stkey, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOrdersUnBilled = async (limitFrom, cfeld5, dates) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getUnbilledWorkOrders`,
      {
        group: "class_g33",
        stClass: "1",
        type: "none",
        limitFrom: limitFrom,
        limitTo: "10",
        orderBy: "cfeld1",
        cfields:
          cfeld5 === "alle"
            ? [{ fieldNo: "gisid", val: "NULL", opVal: "=" },{ fieldNo: "cfeld30", val: "workorder", opVal: "=" }]
            : [
                { fieldNo: "gisid", val: "NULL", opVal: "=" },
                { fieldNo: "cfeld5", val: cfeld5, opVal: "=" },
                { fieldNo: "cfeld30", val: "workorder", opVal: "=" },
              ],
        nfields: [
          {
            fieldNo: "nfeld5",
            fromVal: `${reverseDateFormat(dates[0])}0000`,
            opVal: "between",
            toVal: `${reverseDateFormat(dates[1])}2400`,
          },
        ],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addOrder_baseDataBill = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}createBillFromWorkOrders`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editOrder_baseData = async (values, stkey) => {
  console.log(values);
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 33,
        active: 1,
        stkey: stkey,
        stclass: 3,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: "none",
        objID: key,
        group: "33",
        stClass: "3",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        detclass: 0,
        stclass: 3,
        group: 33,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        detclass: 0,
        group: 33,
        stclass: 3,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}removeFile`,
      {
        stkey: key,
        cfeld1: filename,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getCostCenterListForOrder`,
      {
        group: "class_g33",
        stClass: "6",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const cancelBill = async (value) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}cancelBillFromWorkOrders`,
      {
        group: 33,
        active: 1,
        stclass: 3,
        gisid: " ",
        ...value,
        cfeld2: "cancelled",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const recreateBill = async (value) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}reCreateBillDocument`,
      {
        stkey: value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

// Function to reverse a date string from ddMMYYYY to YYYYMMdd format
export function reverseDateFormat(dateStr) {
  if (dateStr.length !== 8) {
    console.error(
      "Invalid date format. Ensure the date is in ddMMYYYY format."
    );
    return null;
  }

  // Extract day, month, and year
  const day = dateStr.slice(0, 2); // First two characters represent the day (dd)
  const month = dateStr.slice(2, 4); // Next two characters represent the month (MM)
  const year = dateStr.slice(4); // Remaining characters represent the year (YYYY)

  // Rearrange to YYYYMMdd format
  const reversedDateStr = `${year}${month}${day}`;

  return reversedDateStr;
}
