import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Select,
} from "antd";
import { useRef, useState, useEffect } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editCheck } from "./network_requests";
import { double_to_string, string_to_double } from "../../../../constants";

export default function EditCheck(props) {
  const { open, setOpen, fields, setAction, stkey } = props;
  const history = useHistory();
  const ref = useRef();
  const [root, setRoot] = useState(null);
  const [stem, setStem] = useState(null);
  const [crown, setCrown] = useState(null);
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nextControl, setNextControl] = useState(
    moment()?.add(1, "Y")?.format("DD.MM.YYYY")
  );
  const { Text } = Typography;
  const { Option } = Select;

  useEffect(() => {
    setnfeld1(
      open?.nfeld1
        ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setNextControl(
      open?.nfeld1
        ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY")
            ?.add(1, "Y")
            ?.format("DD.MM.YYYY")
        : nextControl
    );
    setRoot(open?.cfeld5);
    setStem(open?.cfeld4);
    setCrown(open?.cfeld3);
  }, [open]);

  return (
    <Modal
      title={<IntlMessages id="outdoor.tree.check.editcheck"/>}
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(null);
        setAction(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
        setAction(null);
        setnfeld1(moment(moment(), "DD.MM.YYYY"));
        setNextControl(moment()?.add(1, "Y")?.format("DD.MM.YYYY"));
      }}
      style={{
        maxWidth: "920px",
      }}
    >
      <Form
        name="add Tree"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editCheck(
            {
              ...open,
              ...values,
              nfeld1: string_to_double(nfeld1),
              cfeld5: root,
              cfeld4: stem,
              cfeld3: crown,
            },
            stkey
          );
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.date" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                  setNextControl(
                    moment(d, "DD.MM.YYYY")?.add(1, "Y")?.format("DD.MM.YYYY")
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.nextdate" />}
              style={{ marginBottom: 0 }}
            >
              <Input value={nextControl} />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.value" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.measurementdata" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.grabung" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.tree.check.events" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.findingsroot" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={open?.cfeld5?.split(",")}
                onChange={(val) => {
                  setRoot(val?.join(", "));
                }}
              >
                {fields?.root?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.findingsstem" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={open?.cfeld4?.split(",")}
                onChange={(val) => {
                  setStem(val?.join(", "));
                }}
              >
                {fields?.stem?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="outdoor.findingscrown" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={open?.cfeld3?.split(",")}
                onChange={(val) => {
                  setCrown(val?.join(", "));
                }}
              >
                {fields?.crown?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
