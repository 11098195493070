import { Row, Typography, Col, Pagination, Spin, Empty } from "antd";
import tickIcon from "../../../assets/Icons_waste/task_finished.png";
import { doubleDT_to_string } from "../../../constants";
import { useEffect, useState } from "react";
import { getAllCheckRoundsForTemplate } from "./network_requests";
import CheckroundStatus from "./components/CheckroundStatus";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

export default function CheckroundsMain(props) {
  const { template } = props;
  const { state } = useParams();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [round, setRound] = useState(null);
  const navigate = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      const response = await getAllCheckRoundsForTemplate({
        stkey: template?.stkey,
      });
      setLoad(false);
      if (Array.isArray(response)) {
        setData(response);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {state === "checkrounds1" ? (
        <CheckroundStatus round={round} />
      ) : (
        <>
          <Row
            style={{
              alignItems: "center",
              padding: "0px 3px",
            }}
          >
            <Col xs={24} style={{}}>
              <Text style={{ fontSize: "18px" }}>
                Latest Checkrounds of Template "{template?.title}"
              </Text>
            </Col>
          </Row>
          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "20px 0 0 0",
                }}
              >
                <Col
                  xs={2}
                  style={{
                    padding: 0,
                  }}
                ></Col>
                <Col
                  xs={8}
                  style={{
                    padding: 0,
                  }}
                >
                  Title
                </Col>

                <Col
                  xs={8}
                  style={{
                    padding: 0,
                  }}
                >
                  Finished
                </Col>
              </Row>

              {data?.length === 0 ? (
                <Empty description="Keine Objekte vorhanden!" />
              ) : null}

              {data?.map((item) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #eaeaea",
                      padding: "10px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{
                        padding: 0,
                      }}
                    >
                      <img
                        src={tickIcon}
                        style={{
                          height: "22px",
                        }}
                      />
                    </Col>
                    <Col
                      xs={8}
                      style={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRound({ ...item });
                        navigate?.push("checkrounds1");
                      }}
                    >
                      <Text
                        style={{
                          color: "#038fde",
                        }}
                      >
                        {item?.cfeld2}
                        <br />
                        {item?.nfeld1 ? doubleDT_to_string(item?.nfeld1) : "-"}
                      </Text>
                    </Col>
                    <Col
                      xs={8}
                      style={{
                        padding: 0,
                      }}
                    >
                      {item?.nfeld2 ? doubleDT_to_string(item?.nfeld2) : "-"}
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : null}
        </>
      )}{" "}
    </>
  );
}
