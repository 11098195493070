import { Row, Typography, Col, Pagination, Spin, Empty } from "antd";
import MoreActions from "./MoreActions";
import tickIcon from "../assets/boardwPen.png";
import { doubleDT_to_string } from "../../../../constants";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

export default function CheckroundTemplatesMain(props) {
  const { load, data, page, setPage, setData, update, setUpdate } = props;

  const navigate = useHistory();

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={2}
              style={{
                padding: 0,
              }}
            ></Col>
            <Col
              xs={6}
              style={{
                padding: 0,
              }}
            >
              Bezeichnung
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Kategorie
            </Col>

            <Col
              xs={3}
              style={{
                padding: 0,
              }}
            >
              von / bis
            </Col>

            <Col
              xs={3}
              style={{
                padding: 0,
              }}
            >
              De/Aktiviert
            </Col>
            <Col xs={6}>
              <Text></Text>
            </Col>
          </Row>

          {data?.list?.length === 0 ? (
            <Empty description="Keine Objekte vorhanden!" />
          ) : null}

          {data?.list?.map((template) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <img
                    src={tickIcon}
                    style={{
                      height: "22px",
                    }}
                  />
                </Col>
                <Col
                  xs={6}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setData({ ...template });
                    navigate?.push("edit");
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {template?.title}
                  </Text>
                </Col>
                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  {template?.category ? template?.category : "-"}
                </Col>
                <Col
                  xs={3}
                  style={{
                    padding: 0,
                  }}
                >
                  {template?.startTime ? template?.startTime : "-"} <br></br>
                  {template?.endTime ? template?.endTime : "-"}
                </Col>
                <Col
                  xs={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {template?.deactivatedDateTime
                      ? doubleDT_to_string(template?.deactivatedDateTime)
                      : "-"}
                  </Text>
                </Col>

                <Col xs={6}>
                  <MoreActions
                    template={template}
                    setData={setData}
                    update={update}
                    setUpdate={setUpdate}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={data?.count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
