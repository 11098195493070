import { Button, Col, message, Row, Select, Typography } from "antd";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import {
  getAllCheckroundTemplates,
  getCheckpointCategories,
} from "./network_requests";
import CheckroundTemplatesMain from "./components/CheckroundTemplateMain";

const { Text } = Typography;

export default function CheckGroundTemplates({ setData }) {
  const navigate = useHistory();
  const [filter, setFilter] = useState("alle");
  const [categories, setCategories] = useState([]);
  const [checkroundTemplates, setCheckroundTemplates] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getCheckpointCategories();
      if (Array.isArray(response)) {
        setCategories(response);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchAllData() {
      setLoad(true);
      const response = await getAllCheckroundTemplates(filter, page);
      setLoad(false);
      if (response.status === 200) {
        setCheckroundTemplates(response.data);
      } else {
        message.error("Failed to fetch data");
      }
    }
    fetchAllData();
  }, [filter, updateData, page]);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={6} style={{}}>
          <Text style={{ fontSize: "18px" }}>Prüfrunden Vorlagen</Text>
        </Col>

        <Col xs={14}>
          <Select
            defaultValue={filter}
            style={{ width: 380 }}
            onChange={(value) => {
              setFilter(value);
            }}
            options={[
              { label: "alle", value: "alle" },
              ...categories.map((item) => {
                return {
                  label: item?.title,
                  value: item?.title,
                };
              }),
            ]}
          />
        </Col>

        <Col xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="middle"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {
              navigate?.push("insert");
            }}
          >
            Neue Prüfrunden Vorlage
          </Button>
        </Col>
      </Row>
      <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />

      <CheckroundTemplatesMain
        load={load}
        data={checkroundTemplates}
        page={page}
        setPage={setPage}
        setData={setData}
        update={updateData}
        setUpdate={setUpdateData}
      />
    </>
  );
}
