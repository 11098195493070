import React, { useState } from "react";
import { Popover, Tooltip, Select, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
    generatePlannedWork,
    getOptionalOrderTemplatesForDay
  } from "./network_Request";
const { Option } = Select;

const TemplatePopover = ({ day, update, setUpdate }) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [optionalTemplates, setOptionalTemplates] = useState([]);
  const [visible, setVisible] = useState(false);

  const fetchTemplates = async () => {
    try {
      const response = await getOptionalOrderTemplatesForDay(day);
      // Assuming response is an array of objects with id and name
      setOptionalTemplates(response || []);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleVisibleChange = (v) => {
    setVisible(v);
    if (v) {
      fetchTemplates();
    } else {
      // Optionally clear the selection on close
      setSelectedTemplates([]);
    }
  };

  return (
    <Popover
      content={
        <div style={{ minWidth: "240px" }}>
          <Select
            mode="multiple"
            placeholder="Optionale Vorlagen wählen"
            style={{ width: "100%", marginBottom: 10 }}
            value={selectedTemplates}
            onChange={(values) => setSelectedTemplates(values)}
          >
            {optionalTemplates.map((template) => (
              <Option key={template.stkey} value={template.stkey}>
                {template.cfeld1}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={async () => {
              const response = await generatePlannedWork(day, selectedTemplates);
              if (response?.success === "success") {
                setUpdate(!update);
                setVisible(false);
              }
            }}
          >
            Aufgaben + Optionale hinzufügen
          </Button>
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayStyle={{ minWidth: "300px" }}
    >
      <Tooltip title="Aufgaben für den Tag basierend auf Vorlagen erstellen.">
        <PlusCircleOutlined
          style={{ cursor: "pointer", paddingLeft: "7px" }}
        />
      </Tooltip>
    </Popover>
  );
};

export default TemplatePopover;