import { Row, Typography, Col, Button, Pagination, Spin, Select, Input ,message, Popconfirm,
  Tooltip} from "antd";
import { deleteTask, getFields, getTasks, createRequestsFromEmail,getRequestStatus,addTaskToOrder } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import in_progress from "../../../assets/Icons_waste/task_inprogress.png";
import taskOpen from "../../../assets/Icons_waste/task_open.png";
import planned from "../../../assets/Icons_waste/task_planned.png";
import finished from "../../../assets/Icons_waste/task_finished.png";
import AddTask from "./addTask";
import EditTask from "./editTask";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { doubleDT_to_string, double_to_string } from "../../../constants";
import { FileExcelOutlined, MailOutlined,CheckCircleOutlined } from "@ant-design/icons";
import MoreActions from "../../extraComponents/MoreActions";

const { Text } = Typography;
const { Search } = Input;

export default function Task(props) {
  const [Task, setTask] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("open");
  // search state that updates on every input change
  const [search, setSearch] = useState("");
  // searchQuery is used in the getTasks call and is updated when the button is pressed
  const [searchQuery, setSearchQuery] = useState("");
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [requestStatusMessage, setRequestStatusMessage] = useState(""); // New state for overlapping message

  const taskadded = () => {
    message.success("Arbeitsauftrag wurde hinzugefügt!");
  };


  useEffect(() => {
    async function fetchData() {
      setLoad(true);
      // Use searchQuery (only updated on Search button click) as the search parameter
      const Task_ = await getTasks((page - 1) * 10, filter, search);

      if (Task_?.status === 401) {
        localStorage.removeItem("task_id");
        history.go(0);
      }

      const assignments_ = await getFields("G01C04D00F13");
      const persons_ = await getFields("anfragen_verantw");
      setAssignments(assignments_);
      setPersons(persons_);
      setTask(Task_ ? Task_.list : []);
      setCount(Task_?.count ? Task_.count : 0);
      setLoad(false);

      const requestStatusResult = await getRequestStatus();
      setRequestStatusMessage(requestStatusResult || ""); // Store the statustoday message
    }
    fetchData();
  }, [page, updateData, filter, searchQuery]);

  return (
    <>
      <AddTask
        open={addTask}
        setOpen={setAddTask}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />
      <EditTask
        task={editTask}
        setTask={setEditTask}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row align="middle"
            style={{ alignItems: "center", padding: "0px 3px" }}
          >
            {/* Title */}
              <Col xs={4}>
                <Text style={{ fontSize: "18px" }}>Anfragen</Text>
              </Col>
              <Col xs={20} style={{
                  display: "flex",
                  justifyContent: "flex-end" 
                }}>
              {requestStatusMessage && (

                <Text>{requestStatusMessage}</Text>
            )}
            </Col>
            </Row>
            <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />
          <Row align="middle"
            style={{
              alignItems: "center",
              padding: "5px 15px",
            }}
          >

             {/* Filter Dropdown */}
             <Col xs={5} style={{ display: "flex", alignItems: "center" }}>
              <Select
                defaultValue={filter}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFilter(value);
                  setPage(1);
                }}
                options={[
                  { value: "open", label: "eingelangt" },
                  { value: "planned", label: "übernommen" },
                  { value: "finished", label: "abgeschlossen" },
                  { value: "refused", label: "abgelehnt" },
                  { value: "all", label: "alle" },
                  { value: "unverified", label: "nicht verifizierte Mitteilungen" },
                ]}
              />
            </Col>

            {/* Search Input */}
            <Col xs={5} style={{ display: "flex", alignItems: "center" }}>
              <Search
                placeholder="Suchen..."
                value={search}
                style={{ height: 32, lineHeight: "32px", marginTop: 10 }}
                onChange={(e) => setSearch(e.target.value)}
                onSearch={(value) => {
                  setSearchQuery(value);
                  setPage(1); // reset page if needed
                }}
              />
             
            </Col>

            {/* Add Task Button */}
            <Col
              xs={13}
              style={{
                display: "flex",
                justifyContent: "flex-end" 
              }}
            >
              <Button
                size="small"
                type="primary"
                style={{ margin: 0 }}
                onClick={() => setAddTask(!addTask)}
              >
                Anfrage anlegen
              </Button>
            </Col>
            <Col xs={1} style={{ display: "flex",justifyContent: "flex-end" }}>
              <Popconfirm
                title="Mail Anfragen importieren?"
                onConfirm={async () => {
                  const response = await createRequestsFromEmail("issue");
                  if (response?.success) {
                    setUpdateData(!updateData);
                    message.success(response?.success);
                  }
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Anfragen aus Mails importieren!">
                  <MailOutlined style={{ cursor: "pointer", padding: 5 }} />
                </Tooltip>
              </Popconfirm>
          </Col>
          </Row>
          
          
          <div >
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "0 15px 5px 15px",
              }}
            >
              <Col xs={2} style={{ padding: 0 }}>
                <Text>Status</Text>
              </Col>
              <Col xs={7} style={{ padding: 0 }}>
                <Text>
                  <IntlMessages id="tasks.title" />
                </Text>
              </Col>
              <Col xs={3} style={{ padding: 0 }}>
                <Text>erstellt</Text>
              </Col>
              <Col xs={3} style={{ padding: 0 }}>
                <Text>zugewiesene Person</Text>
              </Col>
              <Col xs={6} style={{ padding: 0 }}>
                <Text>Status der Bearbeitung</Text>
              </Col>
              {/*<Col xs={2} style={{ padding: 0 }}>
                <Text>
                 weiteres
                </Text>
              </Col>*/}
              <Col xs={3} style={{ padding: 0 }}>
              </Col>
            </Row>
           

            {Task?.map((task) => (
              <Row
                key={task.id} // Assuming each task has a unique id
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0 15px",
                }}
              >
                <Col xs={2} style={{ padding: 12 }}>
                  <img
                    src={
                      task?.nfeld7
                        ? finished
                        : task?.nfeld5
                        ? in_progress
                        : task?.nfeld6
                        ? planned
                        : taskOpen
                    }
                    style={{ width: "18px" }}
                    alt="task status icon"
                  />
                </Col>
                <Col
                  xs={7}
                  style={{ padding: 0, cursor: "pointer" }}
                  onClick={() => setEditTask(task)}
                >
                  <Text style={{ color: "#038fde" }}>{task?.cfeld1}</Text>
                </Col>
                <Col xs={3} style={{ padding: 0 }}>
                  <Text>
                    {task?.nfeld3 ? doubleDT_to_string(task?.nfeld3) : "-"}
                    <br />
                    {task?.nfeld3 ? task?.cfeld3 : ""}
                  </Text>
                </Col>
                <Col xs={3} style={{ padding: 0 }}>
                  <Text>
                    {task?.cfeld5 ? task?.cfeld5 : "-"}
                  </Text>
                </Col>
                <Col xs={6} style={{ padding: 0 }}>
                  <Text>
                  {task?.nfeld6 ? "seit "+  doubleDT_to_string(task?.nfeld6) + " übernommen" : "-"} <br />
                  {/*task?.nfeld5 ? "seit "+  doubleDT_to_string(task?.nfeld5) + " in Bearbeitung" : "-"*/}
                  {task?.nfeld7
                      ?  "seit "+  doubleDT_to_string(task?.nfeld7) + " abgeschlossen"
                      : task?.nfeld8
                      ?  "seit "+  doubleDT_to_string(task?.nfeld8) + " abgelehnt"
                      : "-"}
                  </Text>
                </Col>
                {/*<Col xs={2} style={{ padding: 0 }}>
                  <Text>
                    weiteres
                  </Text>
                </Col>*/}
                <Col xs={3}>
                  <MoreActions
                    state={task}
                    onDelete={deleteTask}
                    update={updateData}
                    setUpdate={setUpdateData}
                    group={20}
                  />
                  <DownOutlined
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                  <Tooltip title="Arbeitsauftrag aus Anfrage erzeugen!">
                   <CheckCircleOutlined
                        style={{ cursor: "pointer",paddingLeft: "10px", }}
                        onClick={async () => {
                          const response_ = await addTaskToOrder({
                            stkey: task?.stkey,
                          });
                          if (response_?.success === "success") {
                            taskadded();
                          }
                        }}
                      />
                    </Tooltip>
                </Col>
              </Row>
            ))}
            <Row
              style={{
                padding: "10px 30px",
                margin: "0 15px",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={10}
                style={{ margin: 0 }}
                onChange={(newpage) => setPage(newpage)}
              />
            </Row>
          </div>
        </>
      ) : null}
    </>
  );
}