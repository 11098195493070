import Typography from "antd/lib/typography/Typography";
import { Button, Card, Select,Checkbox,TimePicker } from "antd";
import {
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  message,
  Menu,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  addOrder_baseData,
  editOrder_baseData,
  getCostCenter,
} from "./network_Request";
import {
  string_to_doubleDT,
  doubleDT_to_string,
  string_to_double,
} from "../../../../../../constants";
import IntlMessages from "../../../../../../util/IntlMessages";
import StaffForOrder from "./staffForOrder";
import VehiclesForOrder from "./vehiclesForOrder";
import MaterialForOrder from "./materialForOrder";
import { useHistory } from "react-router-dom";

const success = () => {
  message.success("Der Auftrag wurde gespeichert!");
};

const error = () => {
  message.error("Ein Fehler ist passiert - bitte nochmal versuchen.");
};

const successEdit = () => {
  message.success("Der Auftrag wurde bearbeitet!");
};

const errorEdit = () => {
  message.error(
    "Ein Fehler ist passiert - bitte nochmal versuchen zu bearbeiten."
  );
};

const dayFields = {
  Mon: "cfeld22",
  Tue: "cfeld23",
  Wed: "cfeld24",
  Thu: "cfeld25",
  Fri: "cfeld26",
  Sat: "cfeld27",
  Sun: "cfeld28",
};

const daysOfWeek = [
  { label: "Mo", value: "Mon" },
  { label: "Di", value: "Tue" },
  { label: "Mi", value: "Wed" },
  { label: "Do", value: "Thu" },
  { label: "Fr", value: "Fri" },
  { label: "Sa", value: "Sat" },
  { label: "So", value: "Sun" },
];

export default function BaseDataForm(props) {
  const [form] = Form.useForm();
  console.log(props.editOrder);

  const { Option } = Select;
  const { Text } = Typography;

  const [nfeld3, setnfeld3] = useState(
    props?.editOrder?.nfeld3
      ? moment(doubleDT_to_string(props.editOrder.nfeld3), "DD.MM.YYYY HH:mm")
      : null
  );

  const [nfeld5, setnfeld5] = useState(
    props?.editOrder?.nfeld5
      ? moment(doubleDT_to_string(props.editOrder.nfeld5), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld6, setnfeld6] = useState(
    props?.editOrder?.nfeld6
      ? moment(doubleDT_to_string(props.editOrder.nfeld6), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld7, setnfeld7] = useState(
    props?.editOrder?.nfeld7
      ? moment(doubleDT_to_string(props.editOrder.nfeld7), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld8, setnfeld8] = useState(
    props?.editOrder?.nfeld8
      ? moment(doubleDT_to_string(props.editOrder.nfeld8), "DD.MM.YYYY HH:mm")
      : null
  );

  const [costCenter, setCostCenter] = useState(null);
  const [costCenterList, setCostCenterList] = useState(null);
  const [showRecurringDays, setShowRecurringDays] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);

  // Handle dropdown change
  const handleTypeChange = (value) => {
    setShowRecurringDays(value === "recurring");
  
    if (value !== "recurring") {
      // Reset the multi-select value
      form.setFieldsValue({ selectedDays: [] });
      setSelectedDays([]);
      // Also clear all hidden day fields
      const clearedDays = {};
      Object.keys(dayFields).forEach((day) => {
        clearedDays[dayFields[day]] = "";
      });
      form.setFieldsValue(clearedDays);
    }
  };

   // When days are selected, update both the multi-select state and the hidden fields
   const handleDaysChange = (selected) => {
    setSelectedDays(selected);
  
    // Build an object that maps each day field to its value if selected, or an empty string otherwise
    const updatedFields = {};
    Object.keys(dayFields).forEach((day) => {
      updatedFields[dayFields[day]] = selected.includes(day) ? day : "";
    });
  
    form.setFieldsValue(updatedFields);
  };

  useEffect(async () => {
    const fetchedCostCenter = await getCostCenter("0");
    /*{console.log('fetchedCostCenter:', fetchedCostCenter)}*/
    setCostCenterList(fetchedCostCenter);
  }, []);

  useEffect(() => {
    if (props.editOrder?.cfeld30 === "recurring") {
      // Show the recurring days selector
      setShowRecurringDays(true);
      // Derive the selected days from the dayFields values
      const preselectedDays = [];
      Object.keys(dayFields).forEach((day) => {
        if (props.editOrder[dayFields[day]] === day) {
          preselectedDays.push(day);
        }
      });
      // Update state and form field value
      setSelectedDays(preselectedDays);
      form.setFieldsValue({ selectedDays: preselectedDays });
    }
  }, [props.editOrder]);

  const costCenter_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCostCenter(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {costCenterList?.map((field) => {
        /*{console.log('cfeld1:', field?.cfeld1)}*/
        return <Menu.Item key={field?.cfeld1}>{field?.cfeld1}</Menu.Item>;
      })}
    </Menu>
  );

  console.log(nfeld5, nfeld6, nfeld7, nfeld8);

  useEffect(() => {
    setnfeld3(
      props?.editOrder?.nfeld3
        ? moment(doubleDT_to_string(props.editOrder.nfeld3), "DD.MM.YYYY HH:mm")
        : moment(new Date(), "DD.MM.YYYY HH:mm")
    );

    setnfeld5(
      props?.editOrder?.nfeld5
        ? moment(doubleDT_to_string(props.editOrder.nfeld5), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld6(
      props?.editOrder?.nfeld6
        ? moment(doubleDT_to_string(props.editOrder.nfeld6), "DD.MM.YYYY HH:mm")
        : null
    );

    setnfeld7(
      props?.editOrder?.nfeld7
        ? moment(doubleDT_to_string(props.editOrder.nfeld7), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld8(
      props?.editOrder?.nfeld8
        ? moment(doubleDT_to_string(props.editOrder.nfeld8), "DD.MM.YYYY HH:mm")
        : null
    );
    setCostCenter(props?.editOrder?.cfeld5 ? props?.editOrder?.cfeld5 : null);
  }, [props.editOrder]);

  const ref = useRef();
  const [stKey, setStKey] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (stKey) {
      history.push(`/main/baseData-Order?editOrderKey=${stKey}`);
    }
  }, [stKey]);

  useEffect(() => ref.current.resetFields(), [props.editOrder]);

  useEffect(() => {
    if (props.editOrder?.cfeld30 === "recurring") {
      setShowRecurringDays(true);
      const preselectedDays = Object.keys(dayFields).filter(
        (day) => props.editOrder[dayFields[day]] === day
      );
      setSelectedDays(preselectedDays);
      form.setFieldsValue({ selectedDays: preselectedDays });
    }
  }, [props.editOrder, form]);

  return (
    <>
      <Card
        style={{
          borderRadius: "8px",
          margin: "20px 70px 20px 60px",
          padding: 0,
          height: "auto",
          // width: "100%",
        }}
      >
        <Typography.Text>
          <IntlMessages id="workorder.workorder_large" />
        </Typography.Text>
        <Form
          name="add staff"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...props.editOrder, // Spread existing values if present
            cfeld4: localStorage.getItem("uname"), // Keep existing default
            cfeld30: props.editOrder?.cfeld30 || "workorder", // Ensure cfeld30 has a default
            cfeld29: props.editOrder?.cfeld29 || "", // Ensure cfeld27 has a default
             // Set selectedDays if recurring: for each day, if the order’s field equals the day, include it.
            selectedDays:
            props.editOrder?.cfeld30 === "recurring"
              ? Object.keys(dayFields).filter(
                  (day) => props.editOrder[dayFields[day]] === day
                )
              : [],
              // Convert saved time strings to moment objects
            cfeld20: props.editOrder?.cfeld20
            ? moment(props.editOrder.cfeld20, "HH:mm")
            : null,
            cfeld21: props.editOrder?.cfeld21
            ? moment(props.editOrder.cfeld21, "HH:mm")
            : null,
          }}
          style={{
            padding: "0px 30px 0px 70px",
          }}
          onFinish={async (values) => {
            
            if (nfeld5 > nfeld6) {
              message.error("Das Start-Datum muss vor dem End-Datum liegen!");
              return;
            }
            if (nfeld7 > nfeld8) {
              message.error("Das Start-Datum muss vor dem End-Datum liegen!");
              return;
            }
            
            if(nfeld5 != null && nfeld6 != null)
            {
              const startDateString = nfeld5.toString();
              const endDateString = nfeld6.toString();
              if(startDateString.length > 8 || endDateString.length > 8){
                const startDay = startDateString.substring(0, 8);
                const endDay = endDateString.substring(0, 8);
                // Check that the first pair (nfeld5 and nfeld6) are on the same day
                if (startDay !== endDay) {
                  // Replace this with however you want to handle errors (e.g., setting state or showing a message)
                  message.error("Start und End Datum 'verrechenbar' muss innerhalb des selben Tages liegen.");
                  return;
                }
              }
            }

            if(nfeld7 != null && nfeld8 != null)
            {
              const start2DateString = nfeld7.toString();
              console.log(start2DateString);
              const end2DateString = nfeld8.toString();
              if(start2DateString.length > 8 || end2DateString.length > 8){
                const start2Day = start2DateString.substring(0, 8);
                const end2Day = end2DateString.substring(0, 8);
                // Check that the second pair (nfeld7 and nfeld8) are on the same day
                if (start2Day !== end2Day) {
                  message.error("Start und End Datum 'geplant' muss innerhalb des selben Tages liegen.");
                  return;
                }
              }
            }


            if (values.cfeld20) {
              values.cfeld20 = values.cfeld20.format("HH:mm");
            }
            if (values.cfeld21) {
              values.cfeld21 = values.cfeld21.format("HH:mm");
            }

            const dayValues = {};
            Object.keys(dayFields).forEach((day) => {
              // If the day is selected, assign the day value, otherwise an empty string
              dayValues[dayFields[day]] = values.selectedDays?.includes(day) ? day : "";
            });
            
            // Merge the computed day values with the rest of the form values
            const finalValues = { ...values, ...dayValues };

            
            if (!props.editOrder) {
              const response = await addOrder_baseData({
                ...finalValues,
                cfeld5: costCenter,
                nfeld3: nfeld3 ? string_to_doubleDT(nfeld3) : 0,
                nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
                nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
                nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
                nfeld8: nfeld8 ? string_to_doubleDT(nfeld8) : 0,
              });
              if (response?.success) {
                setStKey(response.success);
                success();
              } else {
                error();
              }
            } else {
              const stkey = props.editOrder.stkey;
              const response = await editOrder_baseData({
                ...finalValues,
                stkey,
                cfeld5: costCenter,
                gisid: props?.editOrder?.gisid,
                nfeld3: nfeld3 ? string_to_doubleDT(nfeld3) : 0,
                nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
                nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
                nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
                nfeld8: nfeld8 ? string_to_doubleDT(nfeld8) : 0,
              });
              if (response?.success) {
                successEdit();
              } else {
                errorEdit();
              }
            }
            // props.setUpdate(!props.update);
            // setnfeld3(moment(moment(), "DD.MM.YYYY"));
            // setOpen(!open);
          }}
          //     // onFinishFailed={onFinishFailed}
          //     autoComplete="off"
        >
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item
                name="cfeld1"
                label={<IntlMessages id="workorder.nameTitle" />}
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={1} />
            <Col xs={5}>
              <Form.Item
                name="cfeld2"
                label={<IntlMessages id="workorder.place" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={1} />
            <Col xs={2}>
              <Form.Item
                name="nfeld4"
                type="number"
                label={<IntlMessages id="No." />}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    pattern: /^(\d+)?(\.\d{0,1})?$/, // Pattern for numbers up to one decimal place
                    message:
                      "Bitte eine Zahl mit maximal einer Nachkommastelle eingeben!",
                  },
                ]}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={1} />
            <Col xs={3}>
              <Form.Item
                name="cfeld30"
                label={<IntlMessages id="Art" />}
                rules={[{ required: true, message: "The field cannot be empty" }]}
                style={{ marginBottom: 0 }}
              >
                <Select
                  onChange={handleTypeChange}
                  options={[
                    { label: "Arbeitsauftrag", value: "workorder" },
                    { label: "Dienst geplant", value: "calevent" },
                    { label: "Vorlage", value: "recurring" },
                  ]}
                />
              </Form.Item>
          </Col>

          </Row>

          {showRecurringDays && (
             <Row style={{ width: "100%", height: "95px" }}>
             <Col xs={11}>
               <Form.Item
                 name="selectedDays"
                 label="Wiederholungstage"
                 rules={[{ required: true, message: "Bitte mindestens einen Tag auswählen" }]}
               >
                  <Select
                  mode="multiple"
                  allowClear
                  placeholder="Tage auswählen"
                  onChange={handleDaysChange}
                  options={daysOfWeek}
                  style={{ width: "100%" }}
                />
              </Form.Item>
             </Col>
             <Col xs={1} />
             <Col xs={2}>
              <Form.Item label="von" name="cfeld20">
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    minuteStep={15}
                    />
              </Form.Item>
             </Col>
             <Col xs={1} />
             <Col xs={2}>
              <Form.Item label="bis" name="cfeld21">
                    <TimePicker
                      style={{ width: "100%" }}
                      format="HH:mm"
                      minuteStep={15}
                      />
                </Form.Item>
             </Col>
             <Col xs={1} />
             <Col xs={2}>
              <Form.Item
                name="cfeld29"
                label="Optional"
              >
                <Select
                  options={[
                    { label: "nein", value: "" },
                    { label: "ja", value: "optional" },
                  ]}
                />
              </Form.Item>
              </Col>
           </Row>
          )}

          <Row style={{ width: "100%", height: "95px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld3"
                label={<IntlMessages id="workorder.description" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>


          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item
                name="cfeld5"
                label={<IntlMessages id="workorder.costcenter" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Dropdown overlay={costCenter_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{costCenter}</Text>
                      {console.log("costCenter:", costCenter)}
                    </Col>
                    <Col
                      span={3}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item
                name="cfeld8"
                label={<IntlMessages id="Auftraggeber" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/*<Col xs={11}>
              <Form.Item
                name="nfeld4"
                type="number"
                label={<IntlMessages id="workorder.plannedduration" />}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    pattern: /^(\d+)?(\.\d{0,1})?$/, // Pattern for numbers up to one decimal place
                    message:
                      "Bitte eine Zahl mit maximal einer Nachkommastelle eingeben!",
                  },
                ]}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>*/}
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            
            <Col xs={11}>
              <Form.Item
                name="cfeld4"
                label={<IntlMessages id="workorder.creator" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item
                label={<IntlMessages id="workorder.creationdatetime" />}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld3}
                  disabled
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  onChange={(e, d) => {
                    setnfeld3(d);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {!showRecurringDays && (
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.workstartfrom" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld7}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld7(e);
                    setnfeld8(e ? moment(e, "DD.MM.YYYY HH:mm") : null);
                    form.setFieldsValue({
                      nfeld8: e ? moment(e, "DD.MM.YYYY HH:mm") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.workendat" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld8}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld8(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          )}
          {!showRecurringDays && (
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld6"
                label={<IntlMessages id="workorder.internalcomment" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          )}
          {!showRecurringDays && (
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.billablefrom" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld5}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld5(e);
                    setnfeld6(e ? moment(e, "DD.MM.YYYY HH:mm") : null);
                    form.setFieldsValue({
                      nfeld6: e ? moment(e, "DD.MM.YYYY HH:mm") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.billabletill" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld6}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld6(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          )}
          {!showRecurringDays && (
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld7"
                label={<IntlMessages id="workorder.commentonbill" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          )}

          <Row
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ padding: "0px 60px" }}
              onClick={() => {
                // setOpen(!open);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Row>
        </Form>
      </Card>

      <StaffForOrder orderStaff={props.editOrder} />

      <VehiclesForOrder orderVehicle={props.editOrder} />

      <MaterialForOrder orderMaterial={props.editOrder} />
    </>
  );
}
