import React from "react";
import { Dropdown, Menu, message, Popconfirm, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import {
  createRoundFromTemplate,
  removeCheckroundTemplates,
} from "../network_requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MoreAction = ({ template, setData, update, setUpdate }) => {
  const navigate = useHistory();
  const menu = (
    <Menu>
      <Menu.Item>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate?.push("checkrounds");
            setData(template);
          }}
        >
          Erstellte Prüfrunden anzeigen
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const response = await createRoundFromTemplate({
              stkey: template?.stkey,
            });
            if (response?.success === "saved") {
              message.success("Runde erstellt");
            }
            setUpdate(!update);
          }}
        >
          {" "}
          Prüfrunde von Vorlage erstellen
        </div>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Möchten Sie die Vorlage wirklich löschen?"
          onConfirm={async () => {
            const response = await removeCheckroundTemplates(template);
            if (response?.success === "saved") {
              message.success("Template Removed");
            }
            setUpdate(!update);
          }}
          okText="Yes"
          cancelText="No"
        >
          <div style={{ cursor: "pointer" }}>Vorlage löschen</div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ padding: 20 }}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Typography.Text
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
        >
          <IntlMessages id="more_actions" />
          <DownOutlined
            size="small"
            style={{
              color: "#038fde",
              paddingLeft: "10px",
              fontSize: "10px",
            }}
          />
        </Typography.Text>
      </Dropdown>
    </div>
  );
};

export default MoreAction;
