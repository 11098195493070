import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
  Dropdown,
  Menu,
} from "antd";
import { useRef, useState } from "react";
import moment from "moment";
import { string_to_double } from "../../../../../../constants";
import { DownOutlined } from "@ant-design/icons";

import IntlMessages from "util/IntlMessages";
import { addStaff } from "./network_requests";

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Ein Fehler ist passiert - bitte nochmal versuchen!");
};

export default function AddStaff(props) {
  const { open, setOpen, parent,fields } = props;

  const [nfeld1, setnfeld1] = useState(null);
  const [workorderTeam, setWorkorderTeam] = useState(null);
  const ref = useRef();
  const { Text } = Typography;

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    const stringValue = value.toString();
    return parseFloat(stringValue.includes(",") ? stringValue.replace(/,/g, ".") : stringValue);
  };

  const workorder_team = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setWorkorderTeam(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.workorder_team?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Modal
      title={"Mitarbeiterdaten anlegen"}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{
        minWidth: "58vw",
      }}
    >
      <Form
        name="add staff"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        style={{
          paddingLeft: "20px",
        }}
        onFinish={async (values) => {
          const response = await addStaff(
            {
              ...values,
              nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
              nfeld2: values?.nfeld2 ? commatoPointParser(values.nfeld2) : 0,
              cfeld3: workorderTeam,
            },
            parent
          );
          if (response?.success) {
            success();
          } else {
            error();
          }
          props.setUpdate(!props.update);
          setnfeld1(moment(moment(), "DD.MM.YYYY"));
          setOpen(!open);
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={6}>
            <Form.Item
              name="cfeld1"
              label="Name"
              style={{ marginBottom: 0 }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={6}>
            <Form.Item
              name="cfeld2"
              label="ID"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name="cfeld3" label="Team"  style={{ marginBottom: 0, marginLeft: "10px" }}>
            <Dropdown overlay={workorder_team} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={18}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{workorderTeam}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={6}>
            <Form.Item
              name="nfeld2"
              label="Preis / h (€)"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={6}>
            <Form.Item label="Mitarbeiter seit">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={6}>
            <Form.Item
              name="cfeld4"
              label="Dienststelle"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={6}>
            <Form.Item
              name="cfeld5"
              label="Telefon"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={6}>
            <Form.Item name="cfeld6" label="Führerscheine" style={{ marginBottom: 0, marginLeft: "10px" }}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

         <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={6}>
            <Form.Item
              name="nfeld3"
              label={<IntlMessages id="Reihung (Kalender)" />}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  pattern: /^\d+$/, // Only allows positive whole numbers (integers)
                  message: "Bitte eine ganze Zahl eingeben!",
                },
              ]}
            >
              <Input 
                type="text" // Keep it as text to avoid scientific notation issues in number fields
                inputMode="numeric" // Mobile-friendly numeric keyboard
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  e.target.value = value;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name="cfeld8" label="Farbe" style={{ marginBottom: 0, marginLeft: "10px" }}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item name="cfeld7" label="Kommentar" style={{ marginBottom: 0, marginLeft: "10px" }}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
