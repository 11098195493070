import { Row, Typography } from "antd";
import Tabs from "./tabs";
import { useState } from "react";
import BaseDataForm from "./baseDataform";
import BaseDataUpload from "./baseDataUpload";
import BaseDataComments from "./baseDataComments";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getSpecificOrder } from "./network_Request";
import { filter } from "lodash";

export default function BaseDataOrder() {
  const [tabState, setTabState] = useState(0);
  const [editOrderData, setEditOrderData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const editOrderKey = queryParams.get("editOrderKey");

  const fetchAllOrders = async () => {
    let currentPage = 1;
    let totalPages = 1;

    try {
      const response = await getSpecificOrder(editOrderKey);
      const data = response?.list || [];

      const foundOrder = data.find((order) => order.stkey === editOrderKey);

      if (foundOrder) {
        console.log("Found Order:", foundOrder);
        return foundOrder;
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }

    /*while (currentPage <= totalPages) {
      try {
        const response = await getSpecificOrder((currentPage - 1) * 10);
        const data = response?.list || [];

        const foundOrder = data.find((order) => order.stkey === editOrderKey);

        if (foundOrder) {
          console.log("Found Order:", foundOrder);
          return foundOrder;
        }

        totalPages = Math.ceil(response?.count / 10);
        currentPage++;
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }*/

    console.log("Order not found");
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setEditOrderData(await fetchAllOrders());
    };

    fetchData();
  }, [editOrderKey]);

  return (
    <Row style={{ width: "100%", backgroundColor: "#fff" }}>
      <Row style={{ width: "100%", padding: "0px 40px 0px 60px" }}>
        <Tabs
          tabState={tabState}
          setTabState={setTabState}
          allowChange={editOrderKey ? 1 : 0}
        />
      </Row>
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "70vh",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        {tabState === 0 ? (
          <BaseDataForm editOrder={editOrderData} />
        ) : tabState === 1 ? (
          <BaseDataUpload templateKey={editOrderKey} />
        ) : (
          <BaseDataComments stkey={editOrderKey} />
        )}
      </div>
    </Row>
  );
}
