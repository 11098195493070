import { url } from "../../../../constants";
import axios from "axios";

const latDegree = () => {
  return (0.1 / 6378) * (180 / Math.PI);
};
const lngDegree = (lat) => {
  return ((0.1 / 6378) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180);
};

export const getTrees = async (start, end) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchTreesByName = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld1", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchTreesByStreet = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld6", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchTreesByCenter = async (start, end, lat, lng) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        nfields: [
          {
            fieldNo: "nfeld2",
            fromVal: lat - latDegree(),
            toVal: lat + latDegree(),
            opVal: "between",
          },
          {
            fieldNo: "nfeld1",
            fromVal: lng - lngDegree(lng),
            toVal: lng + lngDegree(lng),
            opVal: "between",
          },
        ],
        cfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTree = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        active: 1,
        group: "3",
        stclass: 2,
        stkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTree = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "3",
        stclass: 2,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getChecks = async (start, end, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getBaumKontrollenAggegiert`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        stKey: stkey,
        detail: "cldetail_g03",
        detClass: 1,
        orderBy: "nfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getActions = async (start, end, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g03",
        stClass: "2",
        type: "none",
        limitFrom: start,
        limitTo: end,
        stKey: stkey,
        detail: "cldetail_g03",
        detClass: 2,
        orderBy: "nfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addCheck = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertDetails`,
      [...values],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editCheck = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,

      {
        ...values,
        detTable__: "cldetail_g03",
        active: 1,
        stkey: stkey,
        stclass: 2,
        detclass: 1,
        gisid: "",
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAction = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetails`,
      [
        {
          detTable__: "cldetail_g03",
          detkey: "",
          active: 1,
          stkey: stkey,
          stclass: 2,
          detclass: 2,
          ...values,
          gisid: "",
        },
      ],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editAction = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,

      {
        ...values,
        detTable__: "cldetail_g03",
        active: 1,
        stkey: stkey,
        stclass: 2,
        detclass: 2,
        gisid: "",
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const excelForTreesAndChecks = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForTreesAndChecks`,
      {
        group: "3",
        stclass: 2,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};