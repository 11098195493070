import {
  Button,
  Col,
  Row,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import IntlMessages from "../../../../../util/IntlMessages";
import moment from "moment";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { FileExcelOutlined, MailOutlined } from "@ant-design/icons";
import {
  getExcelForWorkOrderCalendar,
  createOrdersFromEmail,
  getUserGroups,
} from "../workOrders/components/network_Request";
import CustomCalendar from "../workOrders/components/customCalender";
import { Checkbox } from "antd";

const { Text } = Typography;
const { RangePicker } = DatePicker;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function WorkOrdersCalender() {
  const navigate = useHistory();
  // const [filter, setFilter] = useState("planned");
  const [teams, setTeams] = useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);

  const thisWeekDates = [
    moment()?.startOf("week").format("DDMMYYYY"),
    moment()?.endOf("week").format("DDMMYYYY"),
  ];
  const savedDates = window.localStorage.getItem("savedDates");

  const [dates, setDates] = useState(
    savedDates ? savedDates.split(",") : thisWeekDates
  );

  const [updateData, setUpdateData] = useState(false);
  const [all, setAll] = useState(true);

  useEffect(() => {
    if (dates) {
      window.localStorage.setItem("savedDates", dates);
    }
  }, [dates]);

  useEffect(() => {
    const fetchgroups = async () => {
      try {
        const data = await getUserGroups();

        setTeams(Array.isArray(data) && data?.length > 0 ? [...data] : []);
      } catch (error) {
        console.error("Failed to fetch cost center data:", error);
      }
    };

    fetchgroups();
  }, []);

  return (
    <>
      <Text style={{ fontSize: "18px", padding: 0 }}>
        1-Klick Planung Mitarbeiter
      </Text>
      <div style={{ border: "1px solid #eaeaea", margin: "12px 0px" }} />
      <Row
        style={{
          alignItems: "center",
          padding: "10px 3px",
        }}
      >
        <Col xs={4} style={{ width: "100%" }}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Team Filter"
            onChange={(value) => {
              setTeamsSelected(value);
            }}
          >
            {teams?.map((team) => {
              return <Select.Option value={team}>{team}</Select.Option>;
            })}
          </Select>
        </Col>
        <Col xs={3} style={{ width: "100%" }}>
          <Checkbox checked={all} onChange={(e) => setAll(e.target.checked)}>
            <IntlMessages id="wordorder.showAbsences" />
          </Checkbox>
        </Col>
        <Col xs={6} style={{ width: "100%", justifyContent: "flex-end" }}>
          <Button.Group
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment()?.startOf("week").format("DDMMYYYY"),
                  moment()?.endOf("week").format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.today" />
            </Button>
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.back" />
            </Button>
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.next" />
            </Button>
          </Button.Group>
        </Col>
        
        <Col
          xs={6}
          style={{
            color: "#2596be",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <RangePicker
            format={"DD.MM.YYYY"}
            value={[moment(dates[0], "DDMMYYYY"), moment(dates[1], "DDMMYYYY")]}
            allowClear={false}
            onChange={(values, formatString) => {
              const modifiedArray = formatString.map((date) => {
                return date.replace(/\./g, "");
              });

              setDates(modifiedArray);
            }}
          />
        </Col>
       <Col xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="Neuen Arbeitsauftrag, Aufgabe oder Vorlage erstellen.">
          <Button
            size="small"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {
              navigate.push("/main/baseData-Order");
            }}
          >
            <IntlMessages id="wordorder.newworkorder" />
          </Button>
          </Tooltip>
        </Col>
        <Col xs={1} style={{ display: "flex",  justifyContent: "flex-end" }}>
              <Popconfirm
                title="Excel Datei erzeugen?"
                onConfirm={async () => {
                  const response = await getExcelForWorkOrderCalendar(dates);
                  if (response?.file) {
                    downloadToLocal(response?.file, response?.filename);
                  }
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Wochenplanung in Excel ausgeben.">
                  <FileExcelOutlined style={{ cursor: "pointer", padding: 5 }} />
                </Tooltip>
              </Popconfirm>
          </Col>
      </Row>
      <CustomCalendar dates={dates} all={all} teams={teamsSelected} />
    </>
  );
}
