import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  TimePicker,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import moment from "moment";
import {
  getEmployees,
  getVehicles,
  insertCheckroundTemplate,
  updateCheckroundTemplate,
  getCheckpointCategories,
} from "../network_requests";
import IntlMessages from "util/IntlMessages";
import { string_to_doubleDT } from "../../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;
const { Text } = Typography;

const CheckroundForm = ({ state, data, setData }) => {
  const [form] = Form.useForm();
  const [interval, setInterval] = useState("weekdays");

  const navigate = useHistory();

  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await getCheckpointCategories();
      if (Array.isArray(response)) {
        setCategories(response);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      const response = await getEmployees();
      if (Array.isArray(response?.list)) {
        setUsers(response?.list);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchVehicles = async () => {
      const response = await getVehicles();
      if (Array.isArray(response?.list)) {
        setVehicles(response?.list);
      }
    };
    fetchVehicles();
  }, []);

  const handleSubmit = async (values) => {
    const formattedValues = {
      ...data,
      title: values.title,
      interval: values.interval,
      category: values.category,
      mon: values.days?.includes("mon") ? "1" : "0",
      tue: values.days?.includes("tue") ? "1" : "0",
      wed: values.days?.includes("wed") ? "1" : "0",
      thu: values.days?.includes("thu") ? "1" : "0",
      fri: values.days?.includes("fri") ? "1" : "0",
      sat: values.days?.includes("sat") ? "1" : "0",
      sun: values.days?.includes("sun") ? "1" : "0",
      startTime: moment(values.startTime).format("HH:mm"),
      endTime: moment(values.endTime).format("HH:mm"),
      responsiblePerson1: values.responsiblePerson1 || "",
      responsiblePerson2: values.responsiblePerson2 || "",
      responsiblePerson3: values.responsiblePerson3 || "",
      vehicle1: values.vehicle1 || "",
      comment: values.comment || "",
    };

    console.log(formattedValues);

    if (state === "edit") {
      const response = await updateCheckroundTemplate(formattedValues);
      if (response?.success === "saved") {
        navigate.push("main");
      }
    } else {
      const response = await insertCheckroundTemplate(formattedValues);
      if (response?.success === "saved") {
        setData(response?.data);
        navigate.push("edit");
      }
    }
  };

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={24} style={{}}>
          <Text style={{ fontSize: "18px" }}>
            Prüfrunden Vorlage{" "}
            {state === "edit" ? "bearbeiten " : "neu anlegen"}
          </Text>
        </Col>
      </Row>

      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...data,
        }}
      >
        <div
          style={{
            margin: "0 auto",
            padding: 40,
            backgroundColor: "#fff",
            marginTop: "25px",
            border: "1px solid #eeeeee",
            borderRadius: "16px",
          }}
        >
          <Row>
            <Col xs={8}>
              <Form.Item  name="title" label="Vorlagen Bezeichnung" rules={[{ required: true, message: "Bitte geben Sie eine Bezeichnung ein" }]}>
                <Input
                  style={{
                    width: "90%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="category" label="Kategorie" rules={[{ required: true, message: "Bitte wählen Sie eine Kategorie" }]}>
                <Select
                  defaultValue={category}
                  style={{ width: "90%" }}
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  options={[
                    ...[...categories]?.map((item) => {
                      return {
                        label: item?.title,
                        value: item?.title,
                      };
                    }),
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Form.Item name="interval" label="Intervall">
                <Select
                  onChange={setInterval}
                  style={{
                    width: "90%",
                  }}
                >
                  <Option value="weekdays">Wochentage</Option>
                  <Option value="weekly">wöchentlich</Option>
                  <Option value="monthly">monatlich</Option>
                  <Option value="none">inaktiv</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={14}>
              {interval === "weekdays" && (
                <Form.Item label="Tage wählen" name="days">
                  <Checkbox.Group>
                    <Checkbox value="mon">Mo</Checkbox>
                    <Checkbox value="tue">Di</Checkbox>
                    <Checkbox value="wed">Mi</Checkbox>
                    <Checkbox value="thu">Do</Checkbox>
                    <Checkbox value="fri">Fr</Checkbox>
                    <Checkbox value="sat">Sa</Checkbox>
                    <Checkbox value="sun">So</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Form.Item label="Start Zeit" name="startTime">
                <TimePicker format="HH:mm" style={{ width: "90%" }} />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label="Abschluss Zeit" name="endTime">
                <TimePicker format="HH:mm" style={{ width: "90%" }} />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name="notify"
                label="Benachrichtigung falls nicht abgeschlossen"
              >
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  <Option value="yes">Ja</Option>
                  <Option value="no">Nein</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Form.Item name="responsiblePerson1" label="MitarbeiterIn 1">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {users?.map((user) => {
                    return (
                      <Option
                        value={`${user.cfeld1}`}
                      >{`${user.cfeld1}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item name="responsiblePerson2" label="MitarbeiterIn 2">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {users?.map((user) => {
                    return (
                      <Option
                        value={`${user.cfeld1}`}
                      >{`${user.cfeld1}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item name="responsiblePerson3" label="MitarbeiterIn 3">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {users?.map((user) => {
                    return (
                      <Option
                        value={`${user.cfeld1}`}
                      >{`${user.cfeld1}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Form.Item name="vehicle1" label="Fahrzeug 1">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {vehicles?.map((veh) => {
                    return (
                      <Option value={`${veh.cfeld2}`}>{`${veh.cfeld2}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="vehicle2" label="Fahrzeug 2">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {vehicles?.map((veh) => {
                    return (
                      <Option value={`${veh.cfeld2}`}>{`${veh.cfeld2}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="vehicle3" label="Fahrzeug 3">
                <Select
                  style={{
                    width: "90%",
                  }}
                >
                  {vehicles?.map((veh) => {
                    return (
                      <Option value={`${veh.cfeld2}`}>{`${veh.cfeld2}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="comment" label="Bemerkung">
            <Input.TextArea
              rows={3}
              placeholder="Bemerkung eingeben"
              style={{
                width: "97%",
              }}
            />
          </Form.Item>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "18px",
          }}
        >
          <Button type="primary" htmlType="submit">
            Vorlage speichern
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default CheckroundForm;
