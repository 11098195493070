import { Input, Form, Typography, Button, Row, Col } from "antd";
import { useRef } from "react";
import { addCheckPoint, editCheckPoint } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function AddCheckPoint(props) {
  const { open, setOpen, category, categoryName } = props;

  const ref = useRef();
  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: "#f3f7ff",
        height: "85vh",
      }}
    >
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Prüfobjekt hinzufügen
          </Typography>
        </div>
      </>
      <Form
        name="edit CheckPoint"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          padding: "10px 30px",
        }}
        onFinish={async (values) => {
          setOpen(null);
          props.setLoad(true);
          const response = await addCheckPoint(
            {
              ...values,
            },
            category,
            categoryName
          );

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label={"Title"} name="cfeld1" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item
          label={"Kommentar"}
          name="cfeld3"
          style={{ marginBottom: 0 }}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Row
          style={{
            marginTop: "16px",
            position: "absolute",
            bottom: "100px",
            display: "flex",
            width: "100%",
            padding: "15px 0",
          }}
        >
          <Col
            xs={12}
            style={{
              padding: 0,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "150px",
              }}
            >
              speichern
            </Button>
          </Col>
        </Row>

        {/* <Row
          style={{
            marginTop: "16px",
            position: "absolute",
            bottom: "50px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "0px 15px",
          }}
        >
          <Col
            xs={12}
            style={{
              padding: 0,
            }}
          >
            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: "150px",
                }}
              >
                Write NFC
              </Button>
            </Form.Item>
          </Col>
          <Col
            xs={12}
            style={{
              padding: 0,
            }}
          >
            <Button
              type="primary"
              style={{
                width: "150px",
              }}
            >
              Read NFC
            </Button>
          </Col>
        </Row> */}
      </Form>
    </div>
  );
}
