import {
  Button,
  Col,
  Row,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import IntlMessages from "../../../../../util/IntlMessages";
import moment from "moment";
import WorkOrdersMain from "./components/workOrdersMain";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { FileExcelOutlined, MailOutlined } from "@ant-design/icons";
import {
  getExcelForWorkOrders,
  createOrdersFromEmail,
} from "./components/network_Request";
import CustomCalendar from "./components/customCalender";

const { Text } = Typography;
const { RangePicker } = DatePicker;

function downloadToLocal(pdf, filename) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

export default function WorkOrders() {
  const navigate = useHistory();
  // Initialize the filter from localStorage or default to "none"
  const initialFilter = localStorage.getItem("filter") || "none";
  const [filter, setFilter] = useState(initialFilter);
  const [dates, setDates] = useState([
    moment()?.startOf("week").format("DDMMYYYY"),
    moment()?.endOf("week").format("DDMMYYYY"),
  ]);
  const [updateData, setUpdateData] = useState(false);

  // Option 1: Directly update localStorage in the onChange handler
  const handleFilterChange = (value) => {
    setFilter(value);
    localStorage.setItem("filter", value);
  };

  // Option 2: Alternatively, you could use an effect:
  // useEffect(() => {
  //   localStorage.setItem("filter", filter);
  // }, [filter]);

  return (
    <>
      <Row style={{ alignItems: "center", padding: "0px 3px" }}>
        <Col xs={4}>
          <Text style={{ fontSize: "18px" }}>
            <IntlMessages id="wordorder.workorders" />
          </Text>
        </Col>
        <Col xs={4}>
          <Select
            defaultValue={filter}
            style={{ width: 192 }}
            onChange={handleFilterChange}
            options={[
              { value: "none", label: "offen" },
              { value: "created", label: "erstellt" },
              { value: "planned", label: "geplant" },
              { value: "finished", label: "abgeschlossen" },
              { value: "all", label: "alle" },
              { value: "recurring", label: "Vorlagen" },
            ]}
          />
        </Col>
        <Col xs={5}>
          <Button.Group style={{ margin: 0, padding: 0 }}>
            <Button
              style={{ margin: 0 }}
              onClick={() => {
                setDates([
                  moment()?.startOf("week").format("DDMMYYYY"),
                  moment()?.endOf("week").format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.today" />
            </Button>
            <Button
              style={{ margin: 0 }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.back" />
            </Button>
            <Button
              style={{ margin: 0 }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.next" />
            </Button>
          </Button.Group>
        </Col>
        <Col
          xs={6}
          style={{
            color: "#2596be",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <RangePicker
            format={"DD.MM.YYYY"}
            value={[moment(dates[0], "DDMMYYYY"), moment(dates[1], "DDMMYYYY")]}
            allowClear={false}
            onChange={(values, formatString) => {
              const modifiedArray = formatString.map((date) => {
                return date.replace(/\./g, "");
              });
              setDates(modifiedArray);
            }}
          />
        </Col>
        <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            type="primary"
            style={{ margin: 0 }}
            onClick={() => {
              navigate.push("/main/baseData-Order");
            }}
          >
            <IntlMessages id="wordorder.newworkorder" />
          </Button>
        </Col>
        <Col xs={1} style={{ display: "flex", alignItems: "center" }}>
          <Popconfirm
            title="Excel Datei erzeugen?"
            onConfirm={async () => {
              const response = await getExcelForWorkOrders("issue");
              if (response?.file) {
                downloadToLocal(response?.file, response?.filename);
              }
            }}
            okText="Ja"
            cancelText="Nein"
          >
            <Tooltip title="Excel Datei erzeugen!">
              <FileExcelOutlined style={{ cursor: "pointer", padding: 5 }} />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Arbeitsaufträge aus Mails erzeugen?"
            onConfirm={async () => {
              const response = await createOrdersFromEmail("issue");
              if (response?.success) {
                setUpdateData(!updateData);
                message.success(response?.success);
              }
            }}
            okText="Ja"
            cancelText="Nein"
          >
            <Tooltip title="Arbeitsaufträge aus Mails!">
              <MailOutlined style={{ cursor: "pointer", padding: 5 }} />
            </Tooltip>
          </Popconfirm>
        </Col>
      </Row>
      <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />

      <WorkOrdersMain
        filter={filter}
        dates={dates}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
    </>
  );
}