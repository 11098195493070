import { Modal, Input, Form, Row, Col, DatePicker,message, } from "antd";
import { useEffect, useRef, useState, } from "react";
import { addHoliday } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { string_to_double } from "../../../../../constants";

export default function AddHoliday(props) {
  const { open, setOpen, updateData, setUpdateData, user } = props;
  const history = useHistory();
  const ref = useRef();
  const [nfeld3, setnfeld3] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setnfeld3(moment(moment(), "DD.MM.YYYY"));
    setnfeld4(moment(moment(), "DD.MM.YYYY"));
  }, [open]);

  return (
    <Modal
      title={<><IntlMessages id="absence.addHoliday" /></>}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{
        minWidth: "600px",
      }}
    >
      <Form
        name="add holiday"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {

          if (string_to_double(nfeld3) > string_to_double(nfeld4)) {
            message.error("Das Start-Datum muss vor dem End-Datum liegen!");
            return;
          }

          props?.setLoad(true);
          const response = await addHoliday(
            {
              ...values,
              nfeld3: string_to_double(nfeld3),
              nfeld4: string_to_double(nfeld4),
            },
            user
          );
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          setUpdateData(!updateData);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item label={<IntlMessages id="absence.begin" />} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld3}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld3(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item label={<IntlMessages id="absence.end" />} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld4}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld4(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld3"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
