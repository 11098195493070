import React, { useState, useEffect } from "react";
import moment from "moment";
import "./CustomWeeklyCalendar.css";
import {
  duplicateWorkOrder,
  getExcelForWorkOrdersPerDay,
  getWordForWorkOrdersPerDay,
  getOrderByDays,
  removeevent_Calender,
  reverseDateFormat,
  generatePlannedWork,
  getOverlappings,
  getStatusToday,
  getOptionalOrderTemplatesForDay
} from "./network_Request";
import {
  doubleDT_to_string,
  double_to_string,
} from "../../../../../../constants";
import IntlMessages from "../../../../../../util/IntlMessages";
import { Button, Popconfirm, Popover, DatePicker, Row,Tooltip } from "antd";
import { useHistory } from "react-router";
import AddCalenderEvent from "./AddCalenderEvent";
import TemplatePopover from "./TemplatePopover";
import { FileExcelOutlined, PlusCircleOutlined, FileWordOutlined } from "@ant-design/icons";

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const CustomWeeklyCalendar = ({ dates, all, teams }) => {
  const [weeklyData, setWeeklyData] = useState([]);
  const [openAddEvent, setOpenAddEvent] = useState(null);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const [update, setUpdate] = useState(false);
  const [overlappingMessage, setOverlappingMessage] = useState(""); // New state for overlapping message
  const [statusTodayMessage, setStatusTodayMessage] = useState(""); // New state for overlapping message
  const [duplicateDate, setDuplicateDate] = useState(null);

  const [rightClickEvent, setRightClickEvent] = useState(null);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });

  const handleRightClick = (e, event) => {
    e.preventDefault();
    setRightClickEvent(event);
    setRightClickPosition({ x: e.pageX, y: e.pageY }); // Use pageX/pageY
    setPopconfirmVisible(true);
  };

  useEffect(() => {
    const fetchData = async (teams) => {
      const generateWeek = (startDate) => {
        const startOfWeek = moment(startDate, "YYYYMMDD");
        const weekDays = Array.from({ length: 7 }, (_, i) =>
          startOfWeek.clone().add(i, "days").format("YYYY-MM-DD")
        );
        return weekDays;
      };

      try {
        const data = await getOrderByDays(dates);

        const startDate = reverseDateFormat(dates[0]);

        const weekDays = generateWeek(startDate);

        const userEvents = {};
        const userTeams = {};
        const userColors = {};
        for (const date in data) {
          data[date]?.forEach((event) => {
            if (
              (all || event.absencetype === 0) &&
              (teams.length === 0 || teams.includes(event.employee_team))
            ) {
              if (!userEvents[event.employee_name]) {
                userEvents[event.employee_name] = {};
                userTeams[event.employee_name] = event.employee_team;
                userColors[event.employee_name] = null; // removed ma color from calendar
              }

              userEvents[event.employee_name][date] = userEvents[
                event.employee_name
              ][date]
                ? [...userEvents[event.employee_name][date], event]
                : [event];
            }
          });
        }

        setUsers(
          Object.keys(userEvents)?.map((user) => ({
            name: user,
            team: userTeams[user],
            color: userColors[user],
          }))
        );
        setWeeklyData({ weekDays, userEvents });

        // Call getOverlappings with the same dates after getOrderByDays
        const overlappingResult = await getOverlappings(dates);
        setOverlappingMessage(overlappingResult || ""); // Store the overlapping message

        const statusTodayResult = await getStatusToday();
        setStatusTodayMessage(statusTodayResult || ""); // Store the statustoday message

      } catch (error) {
        console.error("Error fetching weekly data:", error);
      }
    };

    fetchData(teams);
  }, [dates, all, update, teams]);

  return (
    <div className="weekly-calendar">
      {/* Display the overlapping message above the calendar */}
      {overlappingMessage && (
        <div className="overlapping-message">
          <strong>{overlappingMessage}</strong>
        </div>
      )}
      {statusTodayMessage && (
        <div className="status-message">
          <strong>{statusTodayMessage}</strong>
        </div>
      )}
      {openAddEvent ? (
        <AddCalenderEvent
          open={openAddEvent}
          setOpen={setOpenAddEvent}
          update={update}
          setUpdate={setUpdate}
        />
      ) : null}
      <table>
        <thead>
          <tr>
            <th>
              <IntlMessages id="absences.user" />
            </th>
            {weeklyData.weekDays &&
              weeklyData?.weekDays?.map((day) => (
                <th key={day}>
                   {moment(day).format("DD ddd")}
                  <Popconfirm
                  title="Excel für den Tag erzeugen?"
                  onConfirm={async () => {
                    const response = await getExcelForWorkOrdersPerDay(day);
                    if (response?.file) {
                      downloadToLocal(response?.file, response?.filename);
                    }
                  }}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Tooltip title="Excel Ausgabe Tagesplanung.">
                    <FileExcelOutlined style={{ cursor: "pointer", paddingLeft: "7px" }} />  
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title="Word Dokument für den Tag erzeugen?"
                  onConfirm={async () => {
                    const response = await getWordForWorkOrdersPerDay(day);
                    if (response?.file) {
                      downloadToLocal(response?.file, response?.filename);
                    }
                  }}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Tooltip title="Word Ausgabe Tagesplanung.">
                    <FileWordOutlined style={{ cursor: "pointer", paddingLeft: "7px" }} />  
                  </Tooltip>
                </Popconfirm>
                <TemplatePopover day={day} update={update} setUpdate={setUpdate} />
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user?.name}>
              <td className="user-name">
                {user?.name}

                <br />
                <span style={{ fontSize: "10px" }}>{user?.team}</span>
              </td>
              {weeklyData?.weekDays?.map((day) => (
                <td
                  key={day}
                  className="day-cell"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    console.log("hello");
                    // Add your custom right-click logic here
                  }}
                >
                  {weeklyData?.userEvents[user?.name]?.[day] ? (
                    weeklyData?.userEvents[user?.name][day]?.map(
                      (event, index) => (
                        <Popover
                          content={event?.order_place}
                          title={event.order_title}
                          trigger={
                            event?.absencetype === 0 &&
                            event?.order_event !== "calevent"
                              ? "hover"
                              : "none"
                          }
                        >
                          <div
                            key={index}
                            className={"event-blue"}
                            style={{
                              cursor: "pointer",
                              backgroundColor: user?.color
                                ? user?.color
                                : event?.absencetype === 2
                                ? "#e1cc7c"
                                : event?.absencetype === 1
                                ? "#edb881"
                                : event?.task_type === "workorder"
                                ? "#007bff"
                                : "#2E6F40",
                              opacity:
                                event?.absencetype === 2
                                  ? 1.0
                                  : event?.absencetype === 1
                                  ? 1.0
                                  : event?.already_charged > 0 &&
                                    event.bill_generated?.length > 10
                                  ? 0.4
                                  : event?.already_charged > 0
                                  ? 0.7
                                  : 1.0,
                            }}
                            onClick={() => {
                              if (event?.absencetype === 0)
                                history?.push(
                                  `/main/baseData-Order?editOrderKey=${event?.order_stkey}`
                                );
                            }}
                            onContextMenu={(e) => handleRightClick(e, event)} // Detect right-click
                          >
                            <div
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              {event?.absencetype === 0
                                ? doubleDT_to_string(
                                    event?.planned_start
                                  )?.substring(11)
                                : double_to_string(event?.planned_start)}

                              {event?.absencetype === 0
                                ? " - " +
                                  doubleDT_to_string(
                                    event?.planned_end
                                  )?.substring(11)
                                : event?.absencetype === 1
                                ? " - " + double_to_string(event?.planned_end)
                                : ""}
                            </div>
                            <strong>
                              {event?.absencetype === 0
                                ? event.order_title?.length > 47
                                  ? event.order_title?.substring(0, 47) + "..."
                                  : event.order_title
                                : event?.absencetype === 1
                                ? "Urlaub"
                                : "Abwesenheit"}
                            </strong>
                            <strong>
                              {event?.absencetype === 0 && event.vehicle_id ? (
                                <>
                                  <br />
                                  {event.vehicle_id}
                                </>
                              ) : (
                                ""
                              )}
                              {(event?.absencetype === 2 ||
                                event?.absencetype === 1) &&
                              event.absenceComment ? (
                                <>
                                  <br />
                                  {event.absenceComment}
                                </>
                              ) : (
                                ""
                              )}
                            </strong>
                          </div>
                        </Popover>
                      )
                    )
                  ) : (
                    <div
                      className="no-event"
                      onClick={() => {
                        setOpenAddEvent({
                          employeeName: user?.name,
                          dayDate: day,
                          groupForDay: user?.team,
                        });
                      }}
                    >
                      +
                    </div>
                  )}
                  {
                    <div
                      className="add-event"
                      onClick={() => {
                        setOpenAddEvent({
                          employeeName: user?.name,
                          dayDate: day,
                          groupForDay: user?.team,
                        });
                      }}
                    >
                      +
                    </div>
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {rightClickEvent && (
        <div
          style={{
            position: "fixed", // Use fixed to avoid being affected by parent containers
            top: rightClickPosition.y,
            left: rightClickPosition.x,
            zIndex: 1000,
          }}
        >
          <Popover
            content={
              <div>
                <Popconfirm
                  title="Ereignis löschen?"
                  onConfirm={async () => {
                    const response = await removeevent_Calender({
                      orderStkey: rightClickEvent?.order_stkey,
                      absenceDetKey: rightClickEvent?.absence_detkey,
                    });
                    if (response?.success === "success") {
                      setUpdate(!update);
                    }
                    setPopconfirmVisible(false);
                  }}
                  onCancel={() => setPopconfirmVisible(false)}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Button
                    type="default"
                    style={{
                      backgroundColor: "#5c0512",
                      color: "white",
                    }}
                  >
                    löschen
                  </Button>
                </Popconfirm>

                {rightClickEvent?.absencetype === 0 && (
                  <Popover
                    trigger={"click"}
                    content={
                      <>
                        <DatePicker
                          onChange={(date, dateString) => {
                            setDuplicateDate(dateString);
                          }}
                          style={{
                            width: "100%",
                          }}
                        />
                        <Row>
                          <Button
                            style={{
                              margin: "20px 0 0 20px",
                            }}
                            disabled={!Boolean(duplicateDate)}
                            type="primary"
                            onClick={async () => {
                              const response = await duplicateWorkOrder(
                                rightClickEvent?.order_stkey,
                                duplicateDate
                              );
                              if (response?.success === "success") {
                                setUpdate(!update);
                                setPopconfirmVisible(false);
                              }
                            }}
                          >
                            speichern
                          </Button>
                        </Row>
                      </>
                    }
                  >
                    <Button type="primary">duplizieren</Button>
                  </Popover>
                )}
              </div>
            }
            visible={popconfirmVisible}
            onVisibleChange={(visible) => setPopconfirmVisible(visible)}
          >
            <div style={{ width: "1px", height: "1px" }} />
          </Popover>
        </div>
      )}
    </div>
  );
};

export default CustomWeeklyCalendar;
