import { Row, Typography, Upload, Button, Col, Card, message } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

import {
  downloadFile,
  getFiles,
  removeFile,
  uploadFile,
} from "./network_Request";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("File updated!");
};

const error = () => {
  message.error("Could not upload the file!");
};

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  console.log(linkSource);
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { bill } = props;
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [attribute, setAttribute] = useState("contracts");

  const uploadProps = {
    accept: ".pdf,.doc,.docx",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          setFile({
            filename: file.name,
            file: e.target.result,
          });
        };
        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (file) {
        const response = await uploadFile(file, bill?.stkey, attribute);
        if (response?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        if (response?.success) {
          success();
          setUpdate(!update);
        } else {
          error();
        }
      }
    })();
  }, [file?.file]);

  useEffect(() => {
    (async () => {
      const response1 = await getFiles(bill?.stkey, "contracts");
      if (response1?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      const response2 = await getFiles(bill?.stkey, "other");
      if (response1?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setFiles({
        contracts: [...response1],
        other: [...response2],
      });
    })();
  }, [update]);

  return (
    <Row
      style={{
        marginTop: "15px",
        borderRadius: "8px",
        padding: "20px",
      }}
    >
      <Row style={{ width: "100%", marginTop: "20px" }}>
        <Upload {...uploadProps}>
          <Button
            onClick={() => {
              setAttribute("contracts");
            }}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </Row>

      <Row style={{ width: "100%", marginTop: "20px" }}>
        {files?.contracts?.map((doc) => {
          return (
            <Col xs={12} style={{ padding: "10px" }}>
              <Card style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} justify="end">
                  <CloseOutlined
                    style={{ fontSize: "8px", cursor: "pointer" }}
                    onClick={async () => {
                      const response = await removeFile(
                        doc?.stkey,
                        doc?.cfeld1
                      );
                      if (response?.success) {
                        success();
                        setUpdate(!update);
                      } else {
                        error();
                      }
                    }}
                  />
                </Row>
                <Typography.Text
                  style={{
                    cursor: "pointer",
                    color: "#038fde",
                    textTransform: "lowercase",
                  }}
                  onClick={async () => {
                    const response = await downloadFile(
                      doc?.gisid,
                      doc?.cfeld1
                    );
                    if (response?.file) {
                      downloadToLocal(response?.file, response?.filename);
                      //  downloadPDF(response?.file, response?.filename);
                    }
                  }}
                >
                  {doc?.cfeld1}
                </Typography.Text>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
}
