import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const getTasks = async (start, type) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    // Construct the fromVal and toVal for today's date
    const fromVal = `${year}${month}${day}0000`;
    const toVal = `${year}${month}${day}2400`;

    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "1",
        type: "none",
        limitFrom: start,
        limitTo: start + 10,
        orderBy: "nfeld7 ASC, nfeld3 DESC, nfeld5 DESC",
        cfields: [{ fieldNo: "cfeld30", val: "workorder", opVal: "=" }],
        nfields:
          type === "all"
            ? [{ fieldNo: "nfeld3", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "planned"
            ? [{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: ">" }, { fieldNo: "nfeld5", fromVal: "0", toVal: "0", opVal: "=" }]
            : type === "finished"
            ? [{ fieldNo: "nfeld5", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "today"
            ? [{ fieldNo: "nfeld7", fromVal: fromVal, toVal: toVal, opVal: "between" }]
            : [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadImage = async (file, objID) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const date = moment().format("YYYYMMDD");
    const time = moment().format("HHmm");
    const response = await axios.post(
      `${url}uploadImage`,
      {
        filename: `${date}${time}_${objID}.png`,
        file: file?.slice(22),
        attribute: "signature",
        group: 33,
        stclass: 1,
        detclass: 0,
        date: parseInt(date),
        time: parseInt(time),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: objID,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 33,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 33,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        active: 0,
        group: 33,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
