import CheckroundForm from "./components/CheckroundForm";
import moment from "moment";

export default function InsertCheckroundTemplate({ state, setData }) {
  return (
    <CheckroundForm
      state={state}
      setData={setData}
      data={{
        interval: "weekdays",
        days: ["mon", "tue", "wed", "thu", "fri"],
        startTime: moment("06:30", "HH:mm"),
        endTime: moment("15:30", "HH:mm"),
        notify: "no",
      }}
    />
  );
}
