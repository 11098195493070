import { useState } from "react";
import CheckroundMain from "./Main";
import InsertCheckroundTemplate from "./Insert";
import EditCheckroundTemplate from "./Edit";
import CheckroundsMain from "./CheckroundsMain";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CheckroundTemplates() {
  const { state } = useParams();
  const [selectedData, setSelectedData] = useState({});

  return (
    <div>
      {state === "main" ? (
        <CheckroundMain setData={setSelectedData} />
      ) : state === "insert" ? (
        <InsertCheckroundTemplate state={state} setData={setSelectedData} />
      ) : state === "edit" ? (
        <EditCheckroundTemplate state={state} data={selectedData} />
      ) : state === "checkrounds" || state === "checkrounds1" ? (
        <CheckroundsMain template={selectedData} />
      ) : (
        <CheckroundMain setData={setSelectedData} />
      )}
    </div>
  );
}
