import {
  Row,
  Typography,
  Col,
  Button,
  Popover,
  message,
  Input,
  Empty,
  Modal,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//import { double_to_string, string_to_doubleDT } from "../../../../constants";
import {
  doubleDT_to_string,
  double_to_string,
  string_to_doubleDT,
} from "../../../../../../../constants";
import {
  getArtists,
  editArtist,
  getSelectedEventRequirements,
  editSingleRequirement,
} from "../../networkrequests";
import AddArtist from "../addArtist";
import ArtistFiles from "../artistFiles";
import EditArtist from "../editArtist";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

export default function Checklist(props) {
  const { event } = props;
  const [artists, setArtists] = useState({});
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(false);
  const [updateReq, setUpdateReq] = useState(false);
  const [openArtist, setOpenArtist] = useState(false);
  const [delArtist, setDelArtist] = useState(false);
  const [edArtist, setEditArtist] = useState(null);
  const [reqs, setReqs] = useState(null);
  const [openArtistFile, setOpenArtistFile] = useState(null);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const artists_ = await getArtists(page, event?.stkey);
      if (artists_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setArtists({ ...artists_ });
    })();
  }, [page, update]);

  useEffect(() => {
    (async () => {
      if (event?.stkey) {
        const reqs_ = await getSelectedEventRequirements(
          event?.stkey,
          event?.cfeld30
        );
        if (reqs_?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        setReqs({ ...reqs_ });
      }
    })();
  }, [event, updateReq]);

  return (
    <>
      <Row
        style={{
          marginTop: "15px",
          borderRadius: "8px",
          border: "2px solid #ececec",
          padding: "20px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Text style={{ fontSize: "16px", textTransform: "uppercase" }}>
            Checkliste
          </Text>
          <Row
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              width: "100%",
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col xs={6}>
              <Text>Titel</Text>
            </Col>
            <Col xs={7}>
              <Text>Erledigt (erledigt von)</Text>
            </Col>
            <Col xs={11}>
              <Text>Kommentar</Text>
            </Col>
          </Row>
          {reqs?.count === 0 ? (
            <Row
              style={{
                width: "100%",
                height: "25vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty description="No Requirements Selected" />
            </Row>
          ) : null}
          {reqs?.ercList?.map((category) => {
            return (
              <>
                <Row
                  style={{
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    padding: "10px 30px",
                    margin: "0px",
                  }}
                >
                  <Col xs={24}>
                    <Typography.Text>{category?.category}</Typography.Text>
                  </Col>
                </Row>

                {category?.erList?.map((req) => {
                  return (
                    <Row
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #eaeaea",
                        padding: "10px 30px",
                        margin: 0,
                      }}
                    >
                      <Col
                        xs={6}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text style={{ color: "#038fde" }}>
                          {req?.requirementName}
                        </Text>
                      </Col>
                      <Col
                        xs={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Popover
                          trigger="click"
                          placement="right"
                          content={
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#6feb41",
                                  width: "6vw",
                                }}
                                onClick={async () => {
                                  const time = moment(moment()).format(
                                    "DD.MM.YYYY HH:mm"
                                  );
                                  const response = await editSingleRequirement({
                                    cfeld1: category?.category,
                                    cfeld2: req?.requirementName,
                                    cfeld3:
                                      window.localStorage.getItem("uname"),
                                    cfeld4: req?.comment,
                                    detkey: req?.detkey,
                                    stkey: req?.stkey,
                                    nfeld1: req?.reqKategoryNumber,
                                    nfeld2: req?.reqNameNumber,
                                    nfeld3: 1,
                                    nfeld4: string_to_doubleDT(time),
                                  });
                                  if (response?.success) {
                                    success();
                                    setUpdateReq(!updateReq);
                                  } else {
                                    error();
                                  }
                                }}
                              >
                                erledigt
                              </Button>
                              <br />
                              <Button
                                style={{
                                  backgroundColor: "#ff606c",
                                  width: "6vw",
                                }}
                                onClick={async () => {
                                  const response = await editSingleRequirement({
                                    cfeld1: category?.category,
                                    cfeld2: req?.requirementName,
                                    cfeld3:
                                      window.localStorage.getItem("uname"),
                                    cfeld4: req?.comment,
                                    detkey: req?.detkey,
                                    stkey: req?.stkey,
                                    nfeld1: req?.reqKategoryNumber,
                                    nfeld2: req?.reqNameNumber,
                                    nfeld3: 1,
                                    nfeld4: 0,
                                  });
                                  if (response?.success) {
                                    success();
                                    setUpdateReq(!updateReq);
                                  } else {
                                    error();
                                  }
                                }}
                              >
                                offen
                              </Button>
                            </>
                          }
                        >
                          <Button
                            style={{
                              backgroundColor:
                                req?.dateTimeReqWasDone !== 0
                                  ? "#6feb41"
                                  : "#ff606c",
                              width: "6vw",
                            }}
                          >
                            {req?.dateTimeReqWasDone !== 0
                              ? "erledigt"
                              : "offen"}
                          </Button>
                        </Popover>

                        <Text style={{ color: "#038fde" }}>
                          {req?.dateTimeReqWasDone !== 0 ? req?.userName : ""}
                          <br />
                          {req?.dateTimeReqWasDone !== 0
                            ? doubleDT_to_string(req?.dateTimeReqWasDone)
                            : ""}
                        </Text>
                      </Col>
                      <Col
                        xs={11}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Input
                          defaultValue={req?.comment}
                          style={{ width: "100%" }}
                          onKeyDown={async (e) => {
                            if (e.key === "Enter" || e.key === "Tab") {
                              const response = await editSingleRequirement({
                                cfeld1: category?.category,
                                cfeld2: req?.requirementName,
                                cfeld3: req?.userName,
                                cfeld4: e.target.value,
                                detkey: req?.detkey,
                                stkey: req?.stkey,
                                nfeld1: req?.reqKategoryNumber,
                                nfeld2: req?.reqNameNumber,
                                nfeld3: 1,
                                nfeld4: req?.dateTimeReqWasDone,
                              });
                              if (response?.success) {
                                success();
                                setUpdateReq(!updateReq);
                              } else {
                                error();
                              }
                              console.log(e.target.value);
                            }
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          })}
        </Row>
      </Row>
      <Row
        style={{
          marginTop: "15px",
          borderRadius: "8px",
          border: "2px solid #ececec",
          padding: "20px",
        }}
      >
        <AddArtist
          open={openArtist}
          setOpen={setOpenArtist}
          parent={event?.stkey}
          setUpdate={setUpdate}
          update={update}
        />

        <EditArtist
          open={edArtist}
          setOpen={setEditArtist}
          parent={event?.stkey}
          setUpdate={setUpdate}
          update={update}
        />

        <Row style={{ width: "100%" }}>
          <Text style={{ fontSize: "16px", textTransform: "uppercase" }}>
            Tägliche Planung
          </Text>
          <Row
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              width: "100%",
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col xs={6}>
              <Text>Datum</Text>
            </Col>
            <Col xs={6}>
              <Text>Name</Text>
            </Col>
            <Col xs={6}>
              <Text>Kontaktdaten</Text>
            </Col>
            <Col xs={6}>
              <Text>Aktion</Text>
            </Col>
          </Row>
          {artists?.count === 0 ? (
            <Row
              style={{
                width: "100%",
                height: "25vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty description="Noch keine Künstlerdaten vorhanden!" />
            </Row>
          ) : null}
          {artists?.list?.map((artist) => {
            return (
              <Row
                style={{
                  width: "100%",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: 0,
                }}
                onClick={() => {
                  // navigate("/indoor-event");
                }}
              >
                {artist?.detkey === openArtistFile ? (
                  <Modal
                    visible={Boolean(openArtistFile)}
                    onCancel={() => {
                      setOpenArtistFile(null);
                    }}
                    onClose={() => {
                      setOpenArtistFile(null);
                    }}
                    destroyOnClose
                  >
                    <ArtistFiles stkey={openArtistFile} />
                  </Modal>
                ) : null}
                <Col
                  xs={6}
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  <Text style={{ color: "#038fde" }}>
                     {artist?.nfeld1 ? double_to_string(artist?.nfeld1) : "-"}
                  </Text>
                </Col>
                <Col
                  xs={6}
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  <Text style={{ color: "#038fde" }}>{artist?.cfeld1}</Text>
                </Col>
                <Col
                  xs={6}
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  <Text style={{ color: "#038fde" }}>
                    {artist?.cfeld3}, {artist?.cfeld4}
                  </Text>
                </Col>
                <Col
                  xs={6}
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  <Button
                    type="text"
                    style={{ color: "#038fde", margin: 0 }}
                    onClick={() => {
                      setEditArtist({ ...artist });
                    }}
                  >
                    bearbeiten
                  </Button>

                  <Popover
                    trigger="click"
                    content={
                      <>
                        <Button
                          type="text"
                          onClick={async () => {
                            const response = await editArtist(
                              { ...artist },
                              artist?.stkey,
                              artist?.detkey,
                              "remove"
                            );
                            if (response?.success) {
                              success();
                              setUpdate(!update);
                            } else {
                              error();
                            }
                          }}
                        >
                          Delete
                        </Button>
                        <br />
                        <Button
                          type="text"
                          onClick={() => {
                            setOpenArtistFile(artist?.detkey);
                          }}
                        >
                          Dateien
                        </Button>
                      </>
                    }
                  >
                    <Text
                      style={{ color: "#038fde", cursor: "pointer" }}
                      onClick={() => {
                        setDelArtist(!delArtist);
                      }}
                    >
                      | mehr Aktionen
                    </Text>
                  </Popover>
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "15px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setOpenArtist(!openArtist);
              }}
            >
              Künstler hinzufügen
            </Button>
          </Row>
        </Row>
      </Row>
    </>
  );
}
