import { Row, Typography, Col, Pagination, Spin, Empty } from "antd";
import tickIcon from "../../../../assets/Icons_waste/task_finished.png";
import todoIcon from "../../../../assets/Icons_waste/task_open.png";
import { doubleDT_to_string } from "../../../../constants";
import { useEffect, useState } from "react";
import { getRoundStatus } from "../network_requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

export default function CheckroundStatus(props) {
  const { round } = props;
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      const response = await getRoundStatus({
        ...round,
      });
      setLoad(false);
      if (Array.isArray(response)) {
        setData(response);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={24} style={{}}>
          <Text style={{ fontSize: "18px" }}>
            {round?.cfeld2} {doubleDT_to_string(round?.nfeld1)}
          </Text>
        </Col>
      </Row>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={2}
              style={{
                padding: 0,
              }}
            ></Col>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              Bezeichnung
            </Col>

            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              Ort
            </Col>
          </Row>

          {data?.length === 0 ? (
            <Empty description="Keine Objekte vorhanden!" />
          ) : null}

          {data?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={2}
                  style={{
                    padding: 0,
                  }}
                >
                  {item?.erledigtDatum === 0.0 ? <img src={todoIcon} style={{ height: "22px",}}/> : <img src={tickIcon} style={{ height: "22px",}}/>}
                </Col>
                <Col
                  xs={8}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {item?.objcfeld1}
                  </Text>
                </Col>
                <Col
                  xs={8}
                  style={{
                    padding: 0,
                  }}
                >
                 {item?.objcfeld3 + " " + item?.objcfeld4}
                </Col>
                <Col
                  xs={8}
                  style={{
                    padding: 0,
                  }}
                >
                {item?.erledigtDatum !== 0.0 ? item?.erledigtDatum + <br /> : ""}
                {item?.erledigtDatum !== 0.0 ? item?.erledigtVerantwortlicher + <br /> : ""}
                {item?.erledigtDatum !== 0.0 ? item?.erledigtBemerkung : ""}
                </Col>

              </Row>
            );
          })}
        </>
      ) : null}
    </>
  );
}
