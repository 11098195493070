import { useEffect, useState } from "react";
import { Row, Typography, Col, Spin, Empty, message, Button } from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import { getOrdersBilled } from "./network_Request";
import {
  double_to_string,
  doubleDT_to_string,
} from "../../../../../../constants";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function WorkOrdersUnbilled(props) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const { stkey, refVal } = props;
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const checks = await getOrdersBilled(stkey);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks?.list ? checks?.list : []);
    setLoad(false);
  }, [stkey]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
         <Row
            style={{
              alignItems: "center",
              padding: "0px 3px",
            }}
          >
            <Col xs={20} style={{}}>
              
            </Col>
            <Col xs={4} style={{}}>
              <Button
                size="middle"
                type="primary"
                style={{
                  margin: 0,
                }}
                onClick={() => {
                  refVal?.current?.submit();
                }}
              >
                Rechnungsdaten speichern
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              alignItems: "center",
              padding: "0px 3px",
            }}
          >
            <Col xs={20} style={{}}>
              <Text style={{ fontSize: "15px" }}>Zugeordnete Arbeitsaufträge</Text>
            </Col>
            
          </Row>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              borderTop: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0px",
            }}
          >
            <Col
              span={10}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.titel" />
              </Text>
            </Col>
            <Col
              span={5}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.created" />
              </Text>
            </Col>
            <Col
              span={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.planned" />
              </Text>
            </Col>
            <Col
              span={5}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.ressources" />
              </Text>
            </Col>
          </Row>

          {data?.length === 0 ? (
            <Empty description="Keine Aufträge vorhanden!" />
          ) : null}

          {data?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0px",
                }}
              >
                <Col
                  span={10}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(
                      `/main/baseData-Order?editOrderKey=${item.stkey}`
                    );
                  }}
                >
                  <p style={{ margin: 0, color: "#038fde" }}>
                    {item?.cfeld1 ? item?.cfeld1 : "-"}
                  </p>
                </Col>
                <Col
                  span={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld3 ? doubleDT_to_string(item?.nfeld3) : null}
                  </Text>
                  <br />
                  <Text>{item?.cfeld4}</Text>
                </Col>
                <Col
                  span={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld7 ? double_to_string(item?.nfeld7) : "-"}
                  </Text>
                </Col>
                <Col
                  span={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{item?.cfeld5 ? item?.cfeld5 : "-"}</Text>
                </Col>
              </Row>
            );
          })}
        </>
      ) : null}
    </>
  );
}
