import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Form, Input, Select, message, Modal, Row, Col,TimePicker } from "antd";
import {
  addevent_Calender,
  getUserGroups,
  getVehicleIDs,
  getStartAndEndTime,
  //   addOrder_baseDataBill,
  //   editOrder_baseData,
  //   getgroups,
} from "./network_Request";
import { useHistory } from "react-router-dom";

const AddCalenderEvent = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { open, setOpen, data } = props;

  const [userGroups, setUserGroups] = useState([]);
  const [vehicleIDs, setvehicleIDs] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [fromDateTime, setFromDateTime] = useState(moment("06:00", "HH:mm"));
  const [tillDateTime, setTillDateTime] = useState(moment("15:45", "HH:mm"));


  useEffect(() => {
    const fetchgroups = async () => {
      try {
        const data = await getUserGroups();

        setUserGroups(Array.isArray(data) && data?.length > 0 ? [...data] : []);
      } catch (error) {
        console.error("Failed to fetch user groups data:", error);
      }
    };

    fetchgroups();

    const fetchvehicles = async () => {
      try {
        const data = await getVehicleIDs();

        setvehicleIDs(Array.isArray(data) && data?.length > 0 ? [...data] : []);
      } catch (error) {
        console.error("Failed to fetch vehicle data:", error);
      }
    };

    fetchvehicles();

    const fetchstartendtime = async () => {
      try {
        const data = await getStartAndEndTime();
        
        const fromTime = data.find(param => param.detailkz === "start")?.pvalue || "N/A";
        const tillTime = data.find(param => param.detailkz === "end")?.pvalue || "N/A";
       // setWorkTimes({ from: fromTime, till: tillTime });

       // if (fromTime) setFromDateTime(moment(fromTime, "HH:mm"));
       // if (tillTime) setTillDateTime(moment(tillTime, "HH:mm"));

        const newFrom = moment(fromTime, "HH:mm");
        const newTill = moment(tillTime, "HH:mm");

        form.setFieldsValue({
          fromDateTime: newFrom,
          tillDateTime: newTill
        });

      } catch (error) {
        console.error("Failed to time data:", error);
      }
    };

    fetchstartendtime();

  }, []);

  const formatDateToInput = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatDateToSubmit = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split(".");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (values) => {
    try {
      console.log( values.tillDateTime);
      console.log(values.fromDateTime);
      /*if ( values.tillDateTime.isBefore(values.fromDateTime)) {
        
        message.error("Das Start-Datum muss vor dem End-Datum liegen!");
        return;
      }*/

      const formattedDate = formatDateToSubmit(values.dayDate).replace(/-/g, "");
      const formattedFromTime = values.fromDateTime?.format("HH:mm") || "";
      const formattedTillTime = values.tillDateTime?.format("HH:mm") || "";
      const response = await addevent_Calender({
        ...values,
        dayDate: formattedDate,
        fromDateTime: formattedFromTime, 
        tillDateTime: formattedTillTime, 
      });
      if (response?.success === "success") {
        message.success("Successfully saved!");
        props?.setUpdate(!props?.update);
      } else {
        message.error("Failed to create event!");
      }

      setOpen(null);
    } catch (error) {
      console.error("Failed to insert/update data:", error);
      message.error("An error occurred while saving data.");
    }
  };


  const ref = useRef();

  return (
    <Modal
      open={Boolean(open)}
      style={{
        minWidth: "660px",
      }}
      title={"Ereignis hinzufügen"}
      centered
      destroyOnClose
      okText={"speichern"}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
      }}
    >
      <Form
        form={form}
        ref={ref}
        onFinish={handleSubmit}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
          dayDate: open?.dayDate ? formatDateToInput(open.dayDate) : "",
          fromDateTime,
          tillDateTime,
          typeOfEvent: "calevent",
        }}
      >
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="MitarbeiterIn" name="employeeName">
              <Input />
            </Form.Item>
          </Col>

          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="Datum" name="dayDate">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="Status" name="typeOfEvent">
              <Select
              defaultValue="calevent"
                options={[
                  { label: "Dienst geplant", value: "calevent" },
                  { label: "Arbeitsauftrag", value: "workorder" },
                  { label: "Urlaub", value: "holiday" },
                  { label: "Abwesenheit", value: "absence" },
                ]}
                onChange={(value) => setSelectedStatus(value)}
              />
            </Form.Item>
          </Col>

          {selectedStatus !== "holiday" && selectedStatus !== "absence" && (
            <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="Auftrag" name="titleOfEvent">
              <Input placeholder="Dem Team entsprechend" /> 
            </Form.Item>
          </Col>
          )}
        </Row>
         {/* Conditionally render Team & Fahrzeug only if selectedStatus is NOT "holiday" or "absence" */}
       {selectedStatus !== "holiday" && selectedStatus !== "absence" && (
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="von (geplant)" name="fromDateTime">
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  minuteStep={15}
                  />
            </Form.Item>
          </Col>

          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="bis (geplant)" name="tillDateTime">
            <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  minuteStep={15}
                />
            </Form.Item>
          </Col>
        </Row>)}
        {selectedStatus !== "holiday" && selectedStatus !== "absence" && (
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="Team" name="groupForDay">
              <Select
                options={[
                  ...[...userGroups]?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }),
                ]}
              />
            </Form.Item>
          </Col>
          <Col
            xs={12}
            style={{
              margin: 0,
            }}
          >
            <Form.Item label="Fahrzeug" name="vehicle">
              <Select
                options={[
                  ...[...vehicleIDs]?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>)}

        <Form.Item label="Kommentar" name="comment" rules={[{ required: false }]}>
          <Input.TextArea placeholder="Kommentar" rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCalenderEvent;
