import { url } from "../../../constants";
import axios from "axios";

export const getCheckpointCategories = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getCheckpointCategories`,
      {
        group: "class_g28",
        stClass: "90",
        type: "none",
        limitFrom: "0",
        limitTo: "20",
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllCheckroundTemplates = async (filter, page) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getAllCheckRoundTemplates`,
      {
        group: "class_g28",
        stClass: "90",
        type: "none",
        limitFrom: (page - 1) * 10,
        limitTo: "10",
        orderBy: "cfeld1",
        cfields: filter  === 'alle' ? [] : [{ fieldNo: "cfeld3", val: filter, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeCheckroundTemplates = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}removeCheckRoundTemplateWebApp`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createRoundFromTemplate = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}createRoundFromTemplate`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getEmployees = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "4",
        type: "none",
        limitFrom: 0,
        limitTo: 30,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getVehicles = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "5",
        type: "none",
        limitFrom: limitFrom,
        limitFrom: 0,
        limitTo: 30,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const insertCheckroundTemplate = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}insertCheckRoundTemplateWebApp`,
      { ...data},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateCheckroundTemplate = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}updateCheckRoundTemplateWebApp`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getObjectsForCheckRoundTemplate = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getObjectsForCheckRoundTemplate`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateCheckRoundObjects = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}updateCheckRoundObjects`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllCheckRoundsForTemplate = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getAllCheckRoundsForTemplate`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getRoundStatus = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getRoundStatus`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
