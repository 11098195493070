import { Modal, Input, Form, Row, DatePicker, Typography } from "antd";
import { useRef, useState, useEffect } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editAction, editCheck } from "./network_requests";
import { double_to_string, string_to_double } from "../../../../constants";

export default function MarkActionAsDone(props) {
  const { state, setState, stkey } = props;
  const history = useHistory();
  const ref = useRef();
  const [nfeld3, setnfeld3] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setnfeld3(
      state?.nfeld3
        ? moment(double_to_string(state?.nfeld3), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [state]);

  return (
    <Modal
      title={"als erledigt markieren"}
      width="660"
      visible={Boolean(state)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setState(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setState(null);
        setnfeld3(moment(moment(), "DD.MM.YYYY"));
      }}
      style={{
        maxWidth: "920px",
        minWidth: "660px",
      }}
      
    >
      <Form
        name="done Tree"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...state,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editAction(
            {
              ...state,
              ...values,
              nfeld3: string_to_double(nfeld3),
            },
            stkey
          );
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row style={{
        width: "100%",
        margin: 0,
        padding: 8,
        boxSizing: "border-box",
      }}> 
          <Form.Item
            label={<IntlMessages id="Datum" />}
            style={{ marginBottom: 0, width: "100%" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={nfeld3}
              size="large"
              format="DD.MM.YYYY"
              onChange={(e, d) => {
                setnfeld3(d);
              }}
            />
          </Form.Item>
        </Row>
        <Row style={{
        width: "100%",
        margin: 0,
        padding: 8,
        boxSizing: "border-box",
      }}>
          <Form.Item
            label={<IntlMessages id="Firma" />}
            name="cfeld13"
            style={{ marginBottom: 0, width: "100%" }}
          >
            <Input />
          </Form.Item>
        </Row>
        <Row style={{
        width: "100%",
        margin: 0,
        padding: 8,
        boxSizing: "border-box",
      }}>
          <Form.Item
            label={<IntlMessages id="Kommentar" />}
            name="cfeld15"
            style={{ marginBottom: 0, width: "100%" }}
          >
            <Input />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}
