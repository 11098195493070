import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Typography,
  Menu,
  InputNumber,
  Button,
  DatePicker,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editTask } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { doubleDT_to_string, string_to_doubleDT } from "../../../constants";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

export default function EditTask(props) {
  const { task, setTask, assignments, persons } = props;

  const open = Boolean(task);
  const [assignment, setAssignment] = useState("");
  const [person, setPerson] = useState("");
  const [nfeld5, setnfeld5] = useState(null);
  const [nfeld6, setnfeld6] = useState(null);
  const [nfeld7, setnfeld7] = useState(null);
  const [nfeld8, setnfeld8] = useState(null);
  const [cfeld7, setCfeld7] = useState(null);
  const [cfeld8, setCfeld8] = useState(null);
  const [cfeld9, setCfeld9] = useState(null);
  const [cfeld10, setCfeld10] = useState(null);
  const { Text } = Typography;
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    setAssignment(task?.cfeld4 ? task?.cfeld4 : null);
    setPerson(task?.cfeld5 ? task?.cfeld5 : null);
    setCfeld7(task?.cfeld7 ? task?.cfeld7 : null);
    setCfeld8(task?.cfeld8 ? task?.cfeld8 : null);
    setCfeld9(task?.cfeld9 ? task?.cfeld9 : null);
    setCfeld10(task?.cfeld10 ? task?.cfeld10 : null);
    setnfeld5(
      task?.nfeld5
        ? moment(doubleDT_to_string(task?.nfeld5), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld6(
      task?.nfeld6
        ? moment(doubleDT_to_string(task?.nfeld6), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld7(
      task?.nfeld7
        ? moment(doubleDT_to_string(task?.nfeld7), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld8(
      task?.nfeld8
        ? moment(doubleDT_to_string(task?.nfeld8), "DD.MM.YYYY HH:mm")
        : null
    );
  }, [task]);

  const assignment_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setAssignment(e.key);
      }}
    >
      {assignments?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const person_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setPerson(e.key);
        setCfeld10(window.localStorage.getItem("uname"));
      }}
    >
      {persons?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Modal
      title={
        <>
          Anfrage bearbeiten
        </>
      }
      width="660px"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setTask(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setTask(null);
      }}
    >
      <Form
        name="add Task"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...task,
          nfeld5: nfeld5,
          nfeld6: nfeld6,
          nfeld7: nfeld7,
          nfeld8: nfeld8,
          cfeld8: cfeld8,
          cfeld9: cfeld9,
          cfeld10: cfeld10,
          nfeld3: task
            ? doubleDT_to_string(task?.nfeld3, "DD.MM.YYYY HH:mm")
            : null,
        }}
        onFinish={async (values) => {
          if (values?.cfeld1) {
            setTask(null);
            props.setLoad(true);
            const response = await editTask({
              ...task,
              ...values,
              cfeld4: assignment,
              cfeld5: person,
              cfeld7: cfeld7,
              cfeld8: cfeld8,
              cfeld9: cfeld9,
              cfeld10: cfeld10,
              nfeld3: string_to_doubleDT(values?.nfeld3),
              nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
              nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
              nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
              nfeld8: nfeld8 ? string_to_doubleDT(nfeld8) : 0,
            });

            if (response.status === 401) {
              localStorage.removeItem("task_id");
              history.go(0);
            }

            props.setUpdate(!props.update);
          }
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="tasks.title" />}
          name="cfeld1"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter the title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="tasks.description" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <TextArea rows={6} />
        </Form.Item>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="tasks.creationdatetime" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label="erstellt von"
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
          
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
        <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={"übernommen Datum/Zeit"}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <Row gutter={8}>
                <Col>
                  <Button onClick={() => {
                      const now = moment();
                      setnfeld6(now);
                      setCfeld7(window.localStorage.getItem("uname"));
                    }}
                  >jetzt</Button>
                </Col>
                <Col flex="auto">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={nfeld6} // Controlled value
                    showTime={{ format: "HH:mm" }}
                    format="DD.MM.YYYY HH:mm"
                    minuteStep={15}
                    onChange={(e, d) => {
                      setnfeld6(e);
                      setCfeld7(window.localStorage.getItem("uname"));
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={"übernommen von"}
             
              style={{ marginBottom: 0 }}
            >
              <Input value={cfeld7}  readOnly disabled />
            </Form.Item>
          </Col>
        </Row>
        
        <Row style={{ margin: 0, padding: 0 }}>
        <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item label="zugewiesene Person / Abteilung" style={{ marginBottom: 0 }}>
              <Dropdown overlay={person_menu} trigger="click" onChange>
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{person}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={"zugewiesen von"}
              style={{ marginBottom: 0 }}
            >
              <Input value={cfeld10}  readOnly disabled />
            </Form.Item>
          </Col>
        </Row>

        {!nfeld8 && (
        <Row style={{ margin: 0, padding: 0 }}>
        <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={"abgeschlossen Datum/Zeit"}
              name="nfeld7"
              style={{ marginBottom: 0 }}
            >
              <Row gutter={8}>
                <Col>
                <Button onClick={() => {
                      const now = moment();
                      setnfeld7(now);
                      setCfeld8(window.localStorage.getItem("uname"));
                    }}
                  >jetzt</Button>
                </Col>
                <Col flex="auto">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={nfeld7} // Controlled value
                    showTime={{ format: "HH:mm" }}
                    format="DD.MM.YYYY HH:mm"
                    minuteStep={15}
                    onChange={(e, d) => {
                      setnfeld7(e);
                      setCfeld8(window.localStorage.getItem("uname"));
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={"abgeschlossen von"}
              style={{ marginBottom: 0 }}
            >
             <Input value={cfeld8}  readOnly disabled />
            </Form.Item>
          </Col>
         
        </Row>)}

        {!nfeld7 && (
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
              <Form.Item
                label={"abgewiesen Datum/Zeit"}
                name="nfeld8"
                style={{ marginBottom: 0 }}
              >
                <Row gutter={8}>
                <Col>
                <Button onClick={() => {
                      const now = moment();
                      setnfeld8(now);
                      setCfeld9(window.localStorage.getItem("uname"));
                    }}
                  >jetzt</Button>
                </Col>
                  <Col flex="auto">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={nfeld8} // Controlled value
                      showTime={{ format: "HH:mm" }}
                      format="DD.MM.YYYY HH:mm"
                      minuteStep={15}
                      onChange={(e, d) => {
                        setnfeld8(e);
                        setCfeld9(window.localStorage.getItem("uname"));
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={"abgelehnt von"}
              style={{ marginBottom: 0 }}
            >
              <Input value={cfeld9}  readOnly disabled />
            </Form.Item>
          </Col>
        </Row>)}

        <Form.Item
          label={<IntlMessages id="tasks.comment" />}
          name="cfeld6"
          style={{ marginBottom: "15px" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
